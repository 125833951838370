'use strict';

var brands = require('../../../../app_gtn/cartridge/js/brands'),
    compareWidget = require('../compare-widget'),
    productTile = require('../product-tile'),
    progress = require('../progress'),
    util = require('../util'),
    viewportIs = require('../viewport-is'),
    _ = require('lodash'),
    cAccordion = require('../c-accordion');
var GTM = require('../../../../lib/google/int_google/cartridge/js/GTM');

function infiniteScroll() {
    // getting the hidden div, which is the placeholder for the next page
    var loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]');
    // get url hidden in DOM
    var gridUrl = loadingPlaceHolder.attr('data-grid-url');
    if (loadingPlaceHolder.length === 1) {
        var buttonClickedID = loadingPlaceHolder.attr('data-button-associated');
        $('#' + buttonClickedID).remove();
        var currentpage = loadingPlaceHolder.attr('data-currentpage');
        // switch state to 'loading'
        // - switches state, so the above selector is only matching once
        // - shows loading indicator
        progress.show($('.infinite-scroll-placeholder-' + currentpage));
        loadingPlaceHolder.attr('data-loading-state', 'loading');
        loadingPlaceHolder.addClass('infinite-scroll-loading');


        // named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
        var fillEndlessScrollChunk = function (html) {
            loadingPlaceHolder.removeClass('infinite-scroll-loading');
            loadingPlaceHolder.attr('data-loading-state', 'loaded');
            var infiniteScrollDiv = $(html).filter('.infinite-scroll-div');
            var products = $(html).find('li.grid-tile');

            $('#search-result-items').append(products);
            $('.infinite-scroll-div').replaceWith(infiniteScrollDiv);
        };
        $.ajax({
            type: 'GET',
            dataType: 'html',
            url: gridUrl,
            success: function (response) {
                // update UI
                progress.hide();
                fillEndlessScrollChunk(response);
                if ($(response).find('.infinite-scroll-placeholder')) {
                    var pageSize = $(response).filter('.infinite-scroll-div').attr('data-pagesize');
                    if (typeof pageSize !== 'undefined' || pageSize !== null || pageSize !== '') {
                        pageSize = parseInt(pageSize);
                        var loadUrl = new URL(window.location.href);
                        loadUrl.searchParams.set('sz', pageSize);
                        history.pushState({}, null, loadUrl.toString());
                    }

                }

                productTile.init();
                updateProgressBar();
                /* global app */
                if ('powerreviews' in app) {
                    app.powerreviews.render();
                }
            },
            error: function (response) {
                progress.hide();
            }
        });
    }
}

function updateProgressBar() {
    var pagesize = $('.progress-bar').attr('data-pagesize');
    var maxsize = $('.progress-bar').attr('data-pagemaxsize');
    var percentage = Math.round(((pagesize/maxsize) * 100));
    $('.progress-percent').css('width', percentage + '%');
}

/**
 * @private
 * @function
 * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing
 */
function updateProductListing(url, popped, scrollTop=false, areRefsExpanded=false,$this) {
    if (!popped && (!url || url === window.location.href)) {
        return;
    }
    var isSeeAll = false;
    var isExpanded;
    try{ 
        if($this.hasClass('refinement-anchor')) {
            if($this.closest('.c-accordion__body').hasClass('c-accordion__see-all')) {
                isSeeAll = true;
            }
            isExpanded = $this.data('refinementid');
        }
    }catch(e){
        console.log(e);
    }
    var params = {
        refsExpanded: areRefsExpanded,
        format : 'ajax',
        isSeeAll : isSeeAll,
        isExpanded: isExpanded
    };
    progress.show($('.search-result-content'));

    $('#main').load(util.appendParamsToUrl(url,params), function () {
        compareWidget.init();
        productTile.init();
        brands.init();
        progress.hide();
        $('#mobile-refinements').remove();
        placeRefinements();

        if (scrollTop) {
            $('html, body').animate({
                scrollTop: 0
            }, 300);
        }

        if (!popped) {
            history.pushState(undefined, '', url);
        }
        /* global app */
        if ('powerreviews' in app) {
            app.powerreviews.render();
        }

        // Google tag manager reattach
        GTM.reinit();
    });
}

/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() {
    var $main = $('#main');
    updateProgressBar();
    // compare checked
    $main.on('click', 'input[type="checkbox"].compare-check', function () {
        var cb = $(this);
        var tile = cb.closest('.product-tile');

        var func = this.checked ? compareWidget.addProduct : compareWidget.removeProduct;
        var itemImg = tile.find('.product-image a img').first();
        func({
            itemid: tile.data('itemid'),
            uuid: tile[0].id,
            img: itemImg,
            cb: cb
        });

    });

    // handle events for updating grid
    $main.on('click', '.refinements a, .pagination a, .breadcrumb-refinement-value a', function (e) {
        // don't intercept for category and folder refinements, as well as unselectable
        if ($(this).parents('.category-refinement').length > 0 || $(this).parents('.folder-refinement').length > 0 || $(this).parents('.pt_content-search-result').length > 0 || $(this).parent().hasClass('unselectable')) {
            return;
        }
        e.preventDefault();
        var scrollTop = false;
        if ($(e.currentTarget).is('.search-result-options--bottom a')) {
            scrollTop = true;
        }
        updateProductListing(this.href, false, scrollTop, $(this).closest('.pagination').length === 0,$(this));
    });

    // handle events item click. append params.
    $main.on('click', '.product-tile a:not("#quickviewbutton")', function () {
        var a = $(this);
        // get current page refinement values
        var wl = window.location;

        var qsParams = (wl.search.length > 1) ? util.getQueryStringParams(wl.search.substr(1)) : {};
        var hashParams = (wl.hash.length > 1) ? util.getQueryStringParams(wl.hash.substr(1)) : {};

        // merge hash params with querystring params
        var params = $.extend(hashParams, qsParams);
        if (!params.start) {
            params.start = 0;
        }
        // get the index of the selected item and save as start parameter
        var tile = a.closest('.product-tile');
        var idx = tile.data('idx') ? + tile.data('idx') : 0;

        // convert params.start to integer and add index
        params.start = (+params.start) + (idx + 1);
        // set the hash and allow normal action to continue
        a[0].hash = $.param(params);
    });

    // handle sorting change
    $main.on('change', '.sort-by select', function (e) {
        e.preventDefault();
        updateProductListing($(this).find('option:selected').val(), false, false, $('.mobile-refinement-filter__c-accordion__item').hasClass('is-expanded'), $(this));
    })
    .on('change', '.items-per-page select', function () {
        var refineUrl = $(this).find('option:selected').val();
        if (refineUrl === 'INFINITE_SCROLL') {
            $('html').addClass('infinite-scroll').removeClass('disable-infinite-scroll');
        } else {
            $('html').addClass('disable-infinite-scroll').removeClass('infinite-scroll');
            updateProductListing(refineUrl);
        }
    });

     // Tournament Caddies btn click event 
    $(".TC-btn-create").click(function(){
        event.preventDefault();
        setTimeout(sendEmailToTournamenCaddie, 1000); //execute sendEmailToTournamenCaddie after 1000 milliseconds (1 second)
    });

    // Reload the page when close the popup to re initialize the FORM
    $(".close").click(function(){
        window.location.reload();
    });
}

function sendEmailToTournamenCaddie() {
    var inValid = ($("#TCemailInput").attr("aria-invalid") == "true");
    if ($("#TCemailInput-error").text() == "" && !inValid) {//Validation to only make call once form is valid 
        var url = $("#TournamenetCaddiesUrl").val();
        var activeLocale =$('.locale-select .is-active').text();
        var lang = "En";
        if(activeLocale.includes("FR")){
            lang = "Fr";
        }

        $.ajax({
            type: 'POST',
            dataType: 'JSON',
            data: {
                email: $("#TCemailInput").val(),
                lang:lang
            },
            url: url,
            success: function (response) {

                if (response.errorCode == 400 && response.error == "already Subscribed") {
                    $("#TCvalidation-error").removeClass("hide");
                }else if(response.errorCode == 400 && response.error == "invalid_email"){
                    $("#TCvalidation-error").removeClass("hide");
                    if (lang === "En") {
                        $("#TCvalidation-error").text("Please double check your email address and try again.");
                    } else {
                        $("#TCvalidation-error").text("Veuillez vérifier votre adresse e-mail et réessayer.");
                    }
                } else {
                    $("#TCvalidation-error").addClass("hide");
                    $("#TCemailInput").val("");
                    $('#TCmodalMainContent').css('display', 'none');                   
                    if(lang === "En"){
                        $( ".textTop" ).html("Thank You! <br /> We've just sent you an email, please click on the link in the message to complete your registration. If you did not receive the email please check your promotions and spam folders.");
                    } else {
                        $( ".textTop" ).html("Merci! <br /> Nous venous de vous envoyer un courriel. Veuillez cliquer sur le lien dans le message pour compléter votre inscription. Si vous n'avez pas reçu le courriel, veuillez vérifier vos pourriels ou votre onglet de promotions.");
                    }
                    window.setInterval('window.location.reload()', 15000);//Automatically refresh the page after successful submnission  	
                }
            },
            error: function (error) {
                $("#TCvalidation-error").removeClass("hide");
                if (lang === "En") {
                    $("#TCvalidation-error").text("Please double check your email address and try again.");
                } else {
                    $("#TCvalidation-error").text("Veuillez vérifier votre adresse e-mail et réessayer.");
                }
            }
        });
    }
}

function placeRefinements() {
    if (viewportIs(['mobile','tablet'])) {
        if(!$('#mobile-refinements').length) {

            var $src = $('.js-placerefinements__src');
            var dest = '.js-placerefinements__dest';
            var $dest = $(dest);
            $dest.html('<div id="mobile-refinements">' + $src.html() + '</div>');
        }
    }
    else if($('#mobile-refinements').length) {
        $('#mobile-refinements').remove();
    }
    cAccordion.setJsHeight();
}

exports.init = function () {
    var product = require('./product');
    product.initializeEvents();


    compareWidget.init();
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $(document).on('click','.plp-loadmore', function(){
            infiniteScroll();
        });
    }
    initializeEvents();
    brands.init();
    placeRefinements();

    window.onpopstate = function() {
        // Not necessary on category landing pages
        if (!$('.pt_categorylanding').length) {
            updateProductListing(document.location.href, true);
        }
    };

    var previousWindowWidth = $(window).width();
    $(window).on('resize', _.throttle(e => {
        var newWindowWidth = $(window).width();
        if (newWindowWidth !== previousWindowWidth) {
            previousWindowWidth = newWindowWidth;
            placeRefinements(e);
        }
    }, 100));
};

'use strict';

/**
 * Toggles namespaced 'active' class on html element.
 * 'href' value is used for the namespace. If target
 * is not an 'a[href]' the click will not be bound.
 */

const $html = $('html');

const aToggle = {
    activeClasses: [],
    activeNS: [],
    clearAll() {
        // Clear all active aToggle classes.
        // Only one should be active at a time.
        aToggle.activeClasses.forEach(ac => {
            $html.removeClass(ac);
        });
        aToggle.activeClasses = [];
        aToggle.activeNS = [];
    },
    init() {

        $('body')
        .on('click', 'a[href].js-a-toggle', e => {
            e.preventDefault();
            e.stopPropagation();

            const $tgt = $(e.currentTarget);
            const ns = $tgt.attr('href').replace(/[^a-z-]/ig, '');
            const activeClass = `is-${ns}-active`;
            const isActive = $html.is(`.${activeClass}`);

            aToggle.clearAll();

            if (!isActive) {
                $html.addClass(activeClass);
                // Log class to be cleared later by another toggle if necessary.
                aToggle.activeClasses.push(activeClass);
                aToggle.activeNS.push(ns);
            }

            // Focus to element with ID if set in "data-js-a-toggle-focus"
            // of target link.
            const focusID = $tgt.data('js-a-toggle-focus');
            const focusEl = $(`#${focusID}`);
            if (focusEl.length) {
                $(`#${focusID}`).click().focus();
            }

            // Animate.
            if ($tgt.is('.js-a-toggle--animate')) {
                const $animateTgt = $($tgt.attr('href'));
                if ($animateTgt.length) {
                    $animateTgt.slideToggle();
                }
            }

        })
        .on('click', '[data-js-a-toggle-close]', e => {
            e.preventDefault();
            aToggle.clearAll();
        })
        .on('click', e => {
            let doClear = true;
            for (var i in aToggle.activeNS) {
                let ns = aToggle.activeNS[i];
                if ($(e.target).closest(`#${ns}`).length) {
                    doClear = false;
                    break;
                }
            }

            if (doClear) {
                aToggle.clearAll();
            }

        });
    }
};

module.exports = aToggle;

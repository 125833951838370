'use strict';

const _ = require('lodash');

const stickyClass = 'has-sticky-header';
const $html = $('html');
const $body = $('body');
// const $header = $('#header');
// const $nav = $('#navigation');
const $topBanner = $('#header');
const $plpFilters = $('.mobile-refinement-filter');
// const $headerBanner = $('.header-banner');

if($plpFilters.length >= 1) {
    var plpFilterHeight = $plpFilters.outerHeight();
    var plpFilterOffsetTop = $plpFilters.offset().top || 0;
}

let topBannerHeight = $topBanner.outerHeight();
let headerOffsetTop = $topBanner.offset().top || 0;

const doSticky = () => {
    const scrollTop = Math.max($html.scrollTop(), $body.scrollTop());
    const isSticky = scrollTop >= headerOffsetTop;
    $html.toggleClass(stickyClass, isSticky);
    $body.css('padding-top', isSticky ? topBannerHeight : '');

    if($plpFilters.length === 1) {
        const isStickyPlpFilter = scrollTop >= plpFilterOffsetTop;
        $html.toggleClass(stickyClass, isStickyPlpFilter);
        $body.css('padding-top', isStickyPlpFilter ? plpFilterHeight : '');
    }
};

const resetSticky = () => {
    $html.removeClass(stickyClass);
    $body.css('padding-top', '');
    topBannerHeight = $topBanner.outerHeight();
    headerOffsetTop = $topBanner.offset().top || 0;

    if($plpFilters.length === 1) {
        plpFilterHeight = $plpFilters.outerHeight();
        plpFilterOffsetTop = $plpFilters.offset().top || 0;
    }



    doSticky();
};

module.exports = {
    init() {
        doSticky();
        $(window)
        .on('scroll', doSticky)
        .on('resize', _.throttle(resetSticky, 100));
    }
};

'use strict';
const util = require('../../util');

const ns = 'js-personalization';
const imgNs = 'c-personalization-image__personalized';
const $personalizationFieldset = $(`.${ns}`);
const $imageContainer = $(`.${ns}__image`);
let imageUrlBase = '';

var typingTimer;                //timer identifier
var doneTypingInterval = 1000;  //time in ms, 5 second for example

const imageTemplate = (params) => {
    const url =  util.appendParamsToUrl(imageUrlBase, params);
    return `
        <picture class="c-personalization-image__personalized">
            <source type="image/webp" sourceset="${url}&fmt=webp&w=700, ${url}&fmt=webp&w=1400 2x">
            <img src="${url}&w=700" srcset="${url}&w=1400 2x">
        </picture>
    `;
};

const updateImage = () => {
    const $slider = $imageContainer.closest('.slick-slider');
    const slick = $slider.length ? $slider[0].slick : null;
    const $slide = slick ? $imageContainer.closest('.slick-slide') : null;

    if (slick) {
        slick.goTo($slide.data('slick-index'));
    }

    var l1 = $personalizationFieldset.find('[id$="_monogramLine1"]').val(),
        l2 = $personalizationFieldset.find('[id$="_monogramLine2"]').val(),
        l3 = $personalizationFieldset.find('[id$="_monogramLine3"]').val();

    let params = {
        // ?l1=ALEX%20HARRIS&l2=IS%20THE%20BEST!&l3=SO%20SAY%20ALL%20OF%20US&color=0000CC&font=Arial
        l1: l1 ? l1.toLocaleUpperCase() : '',
        l2: l2 ? l2.toLocaleUpperCase() : '',
        l3: l3 ? l3.toLocaleUpperCase() : '',
        color: $personalizationFieldset.find('[name$="_monogramColor"]:checked').data('color'),
        font: $personalizationFieldset.find('[id$="_monogramFont"]').val()
    };

    if (params.l1 || params.l2 || params.l3) {
        let $oldImage = $imageContainer.find(`.${imgNs}`);
        $imageContainer.prepend(imageTemplate(params)).find(`.${imgNs} img`)
        .load(() => {
            $oldImage.remove();
        });
    }
};

const personalization = {
    init() {
        if ($personalizationFieldset.length) {
            imageUrlBase = $personalizationFieldset.data('image');
            const $inputs = $personalizationFieldset.find(':input');

            $('body').on('change', $inputs, updateImage)

            //on keyup, start the countdown
            .on('keyup', $inputs, () => {
                clearTimeout(typingTimer);
                typingTimer = setTimeout(updateImage, doneTypingInterval);
            })

            //on keydown, clear the countdown
            .on('keydown', $inputs, () => {
                if (this.value) {
                    this.value = this.value.toLocaleUpperCase();
                }
                clearTimeout(typingTimer);
            });
        }
    }
};

module.exports = personalization;

/* global google */

'use strict';
var dialog = require('../dialog');
var util = require('../util');

var geocodeStore = function (store, callback) {
    if (store.latitude && store.longitude) {
        callback(new google.maps.LatLng(store.latitude, store.longitude));
        return;
    }

    var location,
        geocoder = new google.maps.Geocoder();

    geocoder.geocode({'address': store.address}, function(results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
            location = results[0].geometry.location;
        } else {
            if (status === google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
                location = null;
            } else {
                return;
            }
        }

        callback(location);
    });
};

var createStoreDetailsMap = function(store) {
    var mapContainer = document.getElementById('store-details-map');

    var storeDetailsMap = new google.maps.Map(mapContainer, {
        center: {lat: 39, lng: -101},
        zoom: 11,
        disableDefaultUI: true,
        zoomControl: true,
        draggable: util.isMobile,
    });

    geocodeStore(store, function(location) {
        storeDetailsMap.setCenter(location);
        // eslint-disable-next-line no-unused-vars
        var marker = new google.maps.Marker({
            map: storeDetailsMap,
            position: location,
            animation: google.maps.Animation.DROP,
        });
    });
};

exports.init = function () {
    $('.store-details-link').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                open: function() {
                    var storeJson = $('#store-details-map').data('store');
                    createStoreDetailsMap(storeJson);
                }
            }
        });
    });
};

'use strict';
require('slick-carousel');
var dialog = require('./dialog');

const gallery = {
    init() {
        /**
         * Amplience Gallery
         */
        var $gallery = $('.js-gallery');

        $gallery.slick({
            slidesToShow: 5,
            centerMode: true,
            centerPadding: '120px',
            infinite: true,
            rows: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        centerPadding: '98px'
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        centerPadding: '76px'
                    }
                },
            ]
        });

        /**
         * Amplience Shop Dialog
         */
        var $dialogTrigger = $('.js-gallery__dialog-trigger');

        $dialogTrigger.on('click', function(ev) {
            ev.preventDefault();
            dialog.open({
                url: $(ev.target).attr('href'),
                callback: function() {
                    var $uiDialog = $('.ui-dialog');
                    var $uiTitle = $('.ui-dialog-titlebar');
                    var $uiCloseButtons = document.getElementsByClassName('ui-dialog-titlebar-close');
                    var $uiClose = $uiCloseButtons[0];
                    var uiCloseHTML = $uiClose.outerHTML;

                    $uiDialog.prepend(uiCloseHTML);
                    $uiDialog.addClass('s-ugc-dialog');
                    $uiTitle.remove();

                    $('.ui-dialog-titlebar-close').on('click', function() {
                        dialog.close();
                    });
                }
            });
        });
    }
};

module.exports = gallery;

'use-strict';

const dialog = require('./dialog');

/**
 * Add custom functionality for Amplience content types
 */

function getBannerVideo() {
    $('body').on('click', '.js-video-banner-button', function () {
        const $banner = $(this).closest('.js-video-banner');

        if ($banner.length) {
            const $videoTemplate = $banner.find('.js-video-banner-video');

            if ($videoTemplate.length) {
                dialog.open({
                    html: $videoTemplate.html(),
                    options: {
                        dialogClass: 'js-video-banner-dialog s-video-modal'
                    },
                    target: '#amplience-video-banner',
                    callback() {
                        const $video = $('.js-video-banner-dialog').find('video');

                        if ($video.length) {
                            $video[0].play();
                        }
                    }
                });
            }
        }
    });
}

var amplience = {
    contentTypes: {
        videoBanner: {
            init() {
                getBannerVideo();
            },
            methods: {
                getBannerVideo
            }
        }
    },
    init() {
        if (this && this.contentTypes) {
            var contentTypes = this.contentTypes;

            Object.keys(contentTypes).forEach(function (key) {
                var contentType = contentTypes[key];

                if (typeof contentType.init !== 'undefined') {
                    contentType.init();
                }
            });
        }
    }
};

module.exports = amplience;

'use strict';

var _ = require('lodash'),
    dialog = require('../dialog'),
    TPromise = require('promise'),
    util = require('../util'),
    variant = require('../pages/product/variant');

var newLine = '\n';
var storeTemplate = function (store, selectedStoreId, selectedStoreText) {
    selectedStoreId = selectedStoreId || '';
    var address2 = store.address2 || '';
    var isPreferredStore = store.storeId.toString() === selectedStoreId.toString();
    var template = `
    <li class="store-tile${isPreferredStore ? ' store-tile--selected' : ''}" data-store-id="${store.storeId}">
        <p class="store-name">${store.name}</p>`;

    // Availability status optional as this modal is also used for select preferred store.
    if (store.status) {
        template += `<p class="store-status c-store-status-icon c-store-status-icon--${store.statusclass}" data-status="${store.statusclass}">${store.status}</p>`;
    }

    template += `
        <p class="store-address">
            ${store.address1} ${address2}<br/>
            ${store.city}, ${store.stateCode} ${store.postalCode} ${store.countryCode}
        </p>
        <button class="btn select-store-button btn-primary--select-store c-store-status-icon c-store-status-icon--preferred btn-primary" data-store-id="${store.storeId}">
            ${isPreferredStore ? selectedStoreText : Resources.SELECT_STORE}
        </button>
    </li>
    `;

    return template;
};

var storeListTemplate = function (stores, selectedStoreId, selectedStoreText, query) {
    var template = '';
    if (query) {
        template += `
            <div class="find-in-store-modal__postal">
                <div class="find-in-store-modal__postal__query">
                    ${query}
                </div>
                <button class="find-in-store-modal__postal__action js-change-postal" type="button">
                    ${Resources.CHANGE_POSTAL_CODE}
                </button>
            </div>
        `;
    }
    if (stores && stores.length) {
        window.updateZip = false;

        template += `
            <div class="store-list-container">
                <ul class="store-list">`;

        template += _.map(stores, function (store) {
            return storeTemplate(store, selectedStoreId, selectedStoreText);
        }).join(newLine);

        template += `
                </ul>
            </div>
            <div class="store-list-pagination"></div>
        `;
    } else {
        template += '<div class="no-results">' + String.format(Resources.NOSTOREFOUND, SitePreferences.STORE_LOOKUP_DISTANCE) + '</div>';
    }
    return template;
};

var zipPromptTemplate = function (message) {
    if (typeof message === "undefined") {
        message = Resources.ENTER_POSTAL_TO_FIND_STORE;
    }

    return `
    <p class="find-in-store-modal__description">${message}</p>
    <form id="zip-prompt-form"><div id="preferred-store-panel" class="c-form-row form-row">
        <div class="c-form-row__form-field-container">
            <label for="user-zip" class="c-form-row__label">${Resources.POSTAL_CODE}</label>
            <input type="text" id="user-zip" name="zipCode" class="c-form-row__input input-text"
                placeholder="${Resources.ENTER_ZIP}"
                required
                data-msg-required="${Resources.ENTER_ZIP}"
            />
        </div>
    </div></form>
    `;
};


var storeinventory = {
    zipPrompt: function (callback, message) {
        var self = this;
        dialog.open({
            html: zipPromptTemplate(message),
            options: {
                dialogClass: 'find-in-store-modal ui-dialog--relax',
                title: Resources.FIND_IN_STORE,
                buttons: [{
                    text: Resources.SEARCH,
                    class: 'btn btn-primary btn-lg btn-block button-fancy-large',
                    click: function () {
                        if ($('#zip-prompt-form').valid()) {
                            var zipCode = $('#user-zip').val();
                            self.setUserZip(zipCode);
                            if (callback) {
                                callback(zipCode);
                            }
                        }
                    }
                }],
                open: function () {
                    //Need to merge buttonpane into container for layout to display and scroll properly
                    var buttonPane = $('.ui-dialog-buttonpane');
                    buttonPane.detach();
                    $('#dialog-container').append(buttonPane);

                    $('#user-zip').on('keypress', function (e) {
                        if (e.which === 13) {
                            e.preventDefault();
                            // trigger the search button
                            $('.ui-dialog-buttonset .ui-button').trigger('click');
                        }
                        e.target.value = e.target.value.toUpperCase();
                    });

                    $('#user-zip').on('keyup', function (e) {
                        e.target.value = e.target.value.toUpperCase();
                    });

                    dialog.dialogOpen();

                }
            }
        });
    },
    getStoresInventory: function (pid, postal) {
        postal = postal || User.zip;
        return TPromise.resolve($.ajax({
            url: util.appendParamsToUrl(Urls.storesInventory, {
                pid: pid,
                zipCode: postal
            }),
            dataType: 'json'
        }));
    },
    /**
     * @description open the dialog to select store
     * @param {Array} options.stores
     * @param {String} options.selectedStoreId
     * @param {String} options.selectedStoreText
     * @param {Function} options.continueCallback
     * @param {Function} options.selectStoreCallback
     **/
    selectStoreDialog: function (options) {
        var stores = options.stores;
        var selectedStoreId = options.selectedStoreId;
        var selectedStoreText = options.selectedStoreText;
        var context = options.context || '';

        dialog.open({
            html: storeListTemplate(stores, selectedStoreId, selectedStoreText, options.query),
            options: {
                dialogClass: 'find-in-store-modal find-in-store-modal--select-store' + (context ? ' find-in-store-modal--' + context : ''),
                title: options.dialogTitle || Resources.FIND_IN_STORE,
                buttons: [{
                    text: Resources.CONTINUE,
                    class: 'btn btn-primary btn-lg btn-block button-fancy-large',
                    click: function () {
                        dialog.close();
                    }
                }],
                open: function () {
                    //Need to merge buttonpane into container for layout to display and scroll properly
                    var buttonPane = $('.ui-dialog-buttonpane');
                    buttonPane.detach();
                    $('#dialog-container').append(buttonPane);

                    $('.select-store-button').on('click', function (e) {
                        e.preventDefault();
                        var storeId = $(this).data('storeId');
                        // if the store is already selected, don't select again
                        if (storeId === selectedStoreId) { return; }
                        $('.store-list .store-tile')
                            .removeClass('store-tile--selected')
                            .find('.select-store-button').text(Resources.SELECT_STORE);
                        $(this).text(selectedStoreText)
                            .closest('.store-tile')
                            .addClass('store-tile--selected')
                            .removeClass('btn').removeClass('btn-primary')
                            .removeClass('btn-primary--select-store');
                        selectedStoreId = storeId;
                        if (options.selectStoreCallback) {
                            options.selectStoreCallback(storeId);
                        }
                    });

                    dialog.dialogOpen();

                },
                close: function() {
                    document.body.style.overflow = 'auto';
                    variant.reloadProductContent();
                    if (options.continueCallback) {
                        options.continueCallback(stores);
                    }
                }
            }
        });
    },
    setUserZip: function (zip) {
        User.zip = zip;
        $.ajax({
            type: 'POST',
            url: Urls.setZipCode,
            data: {
                zipCode: zip
            }
        });
    },
    shippingLoad: function () {
        var $checkoutForm = $('.address');
        $checkoutForm.off('click');
        $checkoutForm.on('click', 'input[name$="_shippingAddress_isGift"]', function () {
            $(this).parent().siblings('.gift-message-text').toggleClass('hidden', $('input[name$="_shippingAddress_isGift"]:checked').val());
        });
    }
};

module.exports = storeinventory;

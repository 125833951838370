'use strict';

var brands = {
    init: function () {
        const $ns = 'js-sliders';
        const $brand = $(`.${$ns}__brand`);

        if($brand.length && $brand.find('a, span').length > 1) {
            $brand.slick({
                autoplay: true,
                autoplaySpeed: 3500,
                arrows: false,
                dots: true,
                infinite: true,
                lazyLoad: 'progressive',
                slidesToShow: 1,
                speed: 500
            });
        }
    },
};

module.exports = brands;

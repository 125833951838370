'use-strict';

const stickyElem = require('./sticky-elem');

module.exports = {
    init() {
        $('body')
            .on('click', '.js-recently-viewed', function() {
                $(this).closest('.recently-viewed').toggleClass('is-active');
                stickyElem.doSticky();
            });
    }
};

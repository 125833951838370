/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */

'use strict';

require('./polyfills');

var $ = require('jquery');
window.$ = $;
window.jQuery = $;
require('jquery-ui');
require('jquery-validation');
require('svg4everybody')();
require('slick-carousel');

var amplience = require('./amplience'),
    countries = require('./countries'),
    dialog = require('./dialog'),
    minicart = require('./minicart'),
    page = require('./page'),
    searchsuggest = require('./searchsuggest'),
    tooltip = require('./tooltip'),
    qty = require('./qty'),
    aToggle = require('./a-toggle'),
    aScrollTo = require('./a-scroll-to'),
    tabToggle = require('./tab-toggle'),
    recentlyViewed = require('./recently-viewed'),
    accordion = require('./c-accordion'),
    util = require('./util'),
    validator = require('./validator'),
    tls = require('./tls'),
    stickyHeader = require('./sticky-header'),
    stickyElem = require('./sticky-elem'),
    consentTracking = require('./consentTracking'),
    nav = require('./nav'),
    productTile = require('./product-tile'),
    handleScrollBar = require('./handle-scroll-bar'),
    emailSubscribe = require('./emailsubscribe'),
    ecommBridge = require('./ecom-bridge'),
    checkBalance = require('./check-gift-card-balance'),
    preferredStore = require('./preferred-store'),
    gallery = require('./gallery'),
    dailyDealCountdown = require('./daily-deal-countdown'),
    jqueryUiCustom = require('./jqueryUi-custom');

window.ecommBridge = ecommBridge;
window.enableRecaptchaFormSubmit = function() {
    $('.recaptcha-submit').attr('disabled', false);
};

// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js');
    s.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(s);
}

require('./jquery-ext')();
require('./cookieprivacy')();
consentTracking.init();

function initializeEvents() {
    var controlKeys = ['8', '13', '46', '45', '36', '35', '38', '37', '40', '39'];

    $('.form-field-tooltip a').click(function(e) {
        e.preventDefault();
    });

    //GTN-990 review link code changes
    try {
        setTimeout(function() {
            $(document).find('a.pr-snippet-review-count').attr('href','product-reviews-section');
        }, 3000);
    } catch (error) {
        //skip the error
    }

    $('body')
        .on('keydown', 'textarea[data-character-limit]', function (e) {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length;

            if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
                e.preventDefault();
            }
        })
        .on('change keyup mouseup', 'textarea[data-character-limit]', function () {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
        })
        // global dialog close class
        .on('click', '.js-dialog-close', e => {
            e.preventDefault();
            dialog.close();
        });

        $(window).on('load',function () {
            $('.Guestcheckoutcontent-body').hide();
            $('.Guestcheckout-content').hide();
            $('.signindiv').addClass('active');
         });
         $('body').on('click','.guestsignlink',function (e) {
            e.preventDefault();
            $('.signin-sub-body').hide();
            $('.Guestcheckoutcontent-body').show();
            $('.Guestcheckout-content').show();
            $('.guestsignlink').addClass('active');
            $('.signindiv').removeClass('active');
            $('html, body').animate({
                scrollTop: 0
            }, 200);
        });

         $('body').on('click','.signindiv',function (e) {
             e.preventDefault();
             $('.Guestcheckoutcontent-body').hide();
             $('.signin-sub-body').show();
             $('.Guestcheckout-content').hide();
             $('.signindiv').addClass('active');
             $('.guestsignlink').removeClass('active');
         });

    /**
     * initialize search suggestions, pending the value of the site preference(enhancedSearchSuggestions)
     * this will either init the legacy(false) or the beta versions(true) of the the search suggest feature.
     * */
    var $searchContainer = $('.header-search');
    searchsuggest.init($searchContainer, Resources.SIMPLE_SEARCH);

    // add generic toggle functionality
    $('.toggle').next('.toggle-content').hide();
    $('.toggle').click(function () {
        $(this).toggleClass('expanded').next('.toggle-content').toggle();
    });

    var idMatcher = new RegExp('^(#|\\.)');
    $('.privacy-policy, .js-dialog-link').on('click', function (e) {
        e.preventDefault();
        var dialogHeight = $(e.target).data('dialog-height') || 'auto';
        var dialogWidth = $(e.target).data('dialog-width') || 800;
        var dialogTitle = $(e.target).data('dialog-title');

        var url = $(e.currentTarget).attr('href');

        var dialogConfig = {
            options: {
                height: dialogHeight,
                width: dialogWidth
            }
        };

        if (idMatcher.test(url) && $(url).length) {
            // send html from target ID if exists
            dialogConfig.html = $(url).html();
        } else {
            // otherwise assume href is valid URL
            dialogConfig.url = url;
        }

        if (dialogTitle) {
            dialogConfig.options.title = dialogTitle;
        }

        dialog.open(dialogConfig);

    });


    $('.consent-tracking-policy').on('click', function (e) {
        e.preventDefault();
        consentTracking.show();
    });

    $('.user-account').on('click', function (e) {
        e.preventDefault();
        $(this).parent('.user-info').toggleClass('active');
    });

    $(".postal").on("keyup", function() {
        this.value = this.value.toUpperCase();
    });
}
/**
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $('html').addClass('js');
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $('html').addClass('infinite-scroll');
    }
    // load js specific styles
    util.limitCharacters();
}

var pages = {
    account: require('./pages/account'),
    cart: require('./pages/cart'),
    checkout: require('./pages/checkout'),
    compare: require('./pages/compare'),
    product: require('./pages/product'),
    registry: require('./pages/registry'),
    search: require('./pages/search'),
    wishlist: require('./pages/wishlist'),
    storelocator: require('./pages/storelocator'),
    orderconfirmation: require('./pages/checkout/orderconfirmation')
};

var app = {
    init: function () {
        if (document.cookie.length === 0) {
            $('<div/>').addClass('browser-compatibility-alert alert alert-warning').append($('<p/>').addClass('browser-error').html(Resources.COOKIES_DISABLED)).appendTo('#browser-check');
        }

        initializeDom();
        initializeEvents();

        var GTM = require('../../../lib/google/int_google/cartridge/js/GTM');

        // init specific global components
        amplience.init();
        stickyHeader.init();
        stickyElem.init();
        handleScrollBar.init();
        countries.init();
        tooltip.init();
        qty.init();
        aToggle.init();
        aScrollTo.init();
        tabToggle.init();
        recentlyViewed.init();
        accordion.init();
        minicart.init();
        validator.init();
        nav.init();
        productTile.init();
        emailSubscribe.init();
        checkBalance.init();
        preferredStore.init();
        gallery.init();
        dailyDealCountdown.init();
        jqueryUiCustom.init();

        // initialize any data attribute sliders.
        $('[data-slick]').slick();

        // execute page specific initializations
        $.extend(page, window.pageContext);
        var ns = page.ns;
        if (ns && pages[ns] && pages[ns].init) {
            pages[ns].init();
        }

        // Check TLS status if indicated by site preference
        if (SitePreferences.CHECK_TLS === true) {
            tls.getUserAgent();
        }
        window.sgDialog = require('./dialog');
        window.sgUtil = require('./util');
        GTM.init();

        // define trigger for reinitializing GTM on slick recommendations
        $('body').on('gtmReinit', GTM.reinit);
    },
    initEcomBridge : function () {
        /*eslint-disable*/
        ecommBridge.capability.quickView = hasQuickview; ecommBridge.capability.getProduct = hasGetProduct; ecommBridge.capability.url = hasUrl;
        ecommBridge.capability.wishList = hasWishlist; ecommBridge.capability.transactional = isTransactional; ecommBridge.site.locale = currentLocale; ecommBridge.site.currency.code = currencyCode; ecommBridge.site.currency.prefix = currencyIsPrefix ? currencySymbol : ''; ecommBridge.site.currency.suffix = currencyIsPrefix ? '' : currencySymbol; ecommBridge.site.page = pageData;
        ecommBridge.user.name = user;
        /*eslint-enable*/
    }
};

// general extension functions
(function () {
    String.format = function () {
        var s = arguments[0];
        var i, len = arguments.length - 1;
        for (i = 0; i < len; i++) {
            var reg = new RegExp('\\{' + i + '\\}', 'gm');
            s = s.replace(reg, arguments[i + 1]);
        }
        return s;
    };
})();

// We-Chat bar code
$('.st-wechat').on('click', function (e) {
    e.preventDefault();
    var barcode = $('.we-chat-barcode').html();
    dialog.open({
        html: "<div> " + barcode + "</div>",
        options: {
            dialogClass: 'product-remove-confirm-modal weChatBarCode ui-dialog--relax',
            //title: Resources.REMOVE_PRODUCT_TITLE,
            open: function open() {
                $('.model .we-chat-barcode').removeClass('d-none');
                dialog.dialogOpen();
            },
            close: function close() {
                $('.we-chat-barcode').addClass('d-none');
                $('body').css('overflow', 'initial');
            }
        }
    });
});

var focusEl = $('.is-ss-error-message');
if (focusEl !== undefined && focusEl.length > 0) {
    $('#dwfrm_contactus_comment').focus();
}

// initialize app
$(document).ready(function () {
    app.init();
    app.initEcomBridge();
});

'use strict';

/**
 * Click event to apply a smooth scroll animation to url fragment links.
 */
module.exports = {
    init() {

        $(window).on('resize', () => {
            this.headerHeight = $('#header').outerHeight();
        });


        $('#main, #footer').on('click', '[href^="#"]', e => {
            if (!$(e.target).is('.js-dialog-link, .js-a-toggle, .js-no-scroll') && !$(e.currentTarget).hasClass('tab-label')) {
                var frag = $(e.currentTarget).attr('href');

                if (/^#[0-9].?/.test(frag)) {
                    // ignore number fragments (i.e. #0)
                    return false;
                }

                var $destination = frag === '#' ? $('body') : $(frag);

                // To Do: include an offset, so you aren't hiding the top of
                //        the content behind the header
                if ($destination.length) {
                    e.preventDefault();
                    this.scrollTo($destination);
                }
            }

        });
    },
    scrollTo(dest) {
        $('html, body').animate({
            scrollTop: dest.offset().top - this.headerHeight,
        }, 500);
    },
    headerHeight: $('#header').outerHeight()
};

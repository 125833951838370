'use strict';

var checkBalance = {
    init : function() {
        $('#check-giftcert').on('click', function (e) {
            e.preventDefault();
            var url = $(this).data('url');
            var cardId = $('#dwfrm_giftcert_redemption_giftCertCode').val();
            var cardEAN = $('#dwfrm_giftcert_redemption_pin').val();
            var gcCSRF = $('input[name="gc_csrf_token"]').val();
            var billingEmail = $('input[name$="_email_emailAddress"]').val();
            $('#gift-balance').html('').removeClass('error');
            $.ajax({
                url: url,
                data: 'giftCertificateID=' + cardId + '&giftCertificateEAN=' + cardEAN + '&csrf_token=' + gcCSRF + '&billing_email=' + billingEmail,
                success: function (response) {
                    if(!response.error) {
                        $('#gift-balance').html(Resources.GIFT_CERT_BALANCE + ': ' + response.giftCertificate.balance).addClass('success');
                    } else {
                        $('#gift-balance').html(response.error).addClass('error');
                    }
                }
            });
        });

        $('#add-giftcert').on('click', function (e) {
            e.preventDefault();
            var url = $(this).data('url');
            var $checkoutForm = $('.checkout-billing');
            var cardId = $('#dwfrm_giftcert_redemption_giftCertCode').val();
            var cardEAN = $('#dwfrm_giftcert_redemption_pin').val();
            var gcCSRF = $('input[name="gc_csrf_token"]').val();
            var billingEmail = $('input[name$="_email_emailAddress"]').val();
            $('#gift-balance').html('').removeClass('error');
            $.ajax({
                url: url,
                data: 'giftCertCode=' + cardId + '&pin=' + cardEAN + '&csrf_token=' + gcCSRF + '&billing_email=' + billingEmail,
                success: function (response) {
                    if(!response.redeem.error) {
                        if (!response.redeem.nonGCBalance) {
                            // If the GC covers the entire order then submit.
                            $checkoutForm.submit();
                        } else {
                            window.location.assign(Urls.billing);
                        }
                    } else {
                        $('#gift-balance').html(response.redeem.message).addClass('error');
                    }
                }
            });
        });

    }
};

module.exports = checkBalance;

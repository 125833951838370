'use strict';

const _ = require('lodash');
const viewportIs = require('./viewport-is');

const $html = $('html');
const $body = $('body');
const $topBanner = $('#header');

let topBannerHeight = $topBanner.outerHeight();
let headerOffsetTop = $topBanner.offset().top || 0;
let stickyStyles = {
    left: '',
    top: '',
    width: ''
};
let bottomDrawerHeight = 0; //How far from bottom of the page the top of the recently viewed items box is
let newBottomDrawerHeight = 0;
let amtFooterVisible = 0;
let newAmtFooterVisible = 0;
let cutoff = 0;

document.body.style.setProperty('--header-height', topBannerHeight + 'px');

//Methods are declared outside of modules.export despite being used there
//This ends up being the simplest way to allow the throttled resetSticky to still be able to access doSticky
const doSticky = () => {
    if(!viewportIs('desktop')) {
        return;
    }

    const scrollTop = Math.max($html.scrollTop(), $body.scrollTop());
    const footerOffset = $('footer').offset().top;
    const windowBottom = $(window).scrollTop() + window.innerHeight;
    newAmtFooterVisible = windowBottom - Math.ceil(footerOffset);

    if($('.js-recently-viewed').length) {
        newBottomDrawerHeight = Math.abs($('.recently-viewed__items').offset().top - window.innerHeight - $(document).scrollTop()) - 1; //Subtract 1 to offset for -1 margin-top on drawer content to hide "seam"
    }
    if(newBottomDrawerHeight !== bottomDrawerHeight) {
        bottomDrawerHeight = newBottomDrawerHeight;
    }
    //If there's no change in the drawer "height", and the footer is, and was below the visible window, there's no need to reapply the drawer "height" as the bottom attribute
    else if(amtFooterVisible <= 0 && newAmtFooterVisible <= 0) {
        newBottomDrawerHeight = 0;
    }
    amtFooterVisible = newAmtFooterVisible;

    cutoff = Math.max(amtFooterVisible, newBottomDrawerHeight);

    $('.js-sticky-elem').each(function(i, j) {
        let offsetFromHeader = $(j).parent().offset().top - headerOffsetTop - topBannerHeight;
        let stickyPoint = headerOffsetTop + offsetFromHeader - 25;

        if(scrollTop > stickyPoint && !$(j).hasClass('is-sticky')) {
            let stickyStyles = {
                left: $(j).parent().offset().left,
                top: (topBannerHeight),
                width: $(j).parent().outerWidth()
            };

            $(j).css(stickyStyles)
                .addClass('is-sticky');

            if(!$('html').hasClass('has-sticky-elem')) {
                $('html').addClass('has-sticky-elem');
            }
        }
        else if(scrollTop < stickyPoint && $(j).hasClass('is-sticky')) {
            $(j).removeClass('is-sticky')
                .removeClass('is-sticky--custom-bottom')
                .css(stickyStyles);


            return true;
        }

        setStickyBottom(j);
    })
    .promise().done(function() {
        if($('html').hasClass('has-sticky-elem') && !$('.js-sticky-elem.is-sticky').length) {
            $('html').removeClass('has-sticky-elem');
        }
    });
};
const setStickyBottom = (j) => {
    if(!$(j).hasClass('is-sticky')) {
        return;
    }

    let savedScrollTop = $(j).scrollTop(); //save scrollTop before removing bottom so we can preserve it and not have the scroll bar potentiall jump around
    $(j).css('bottom', ''); //reset bottom to get accurate height
    let fromBottom = window.innerHeight - (topBannerHeight + 25 + $(j).outerHeight());

    //Only cut off if there's a visible element that merits a cutoff and the element actually reaches the cut off
    if(cutoff > 0 && fromBottom < cutoff) {
        $(j).addClass('is-sticky--custom-bottom');
        $(j).css('bottom', cutoff + 'px')
            .scrollTop(savedScrollTop);
    }
    else if($(j).hasClass('is-sticky--custom-bottom') && bottomDrawerHeight === 0) {
        $(j).removeClass('is-sticky--custom-bottom');
    }
};
const resetSticky = () => {
    topBannerHeight = $topBanner.outerHeight();
    headerOffsetTop = $topBanner.offset().top || 0;
    document.body.style.setProperty('--header-height', topBannerHeight + 'px');

    $('.js-sticky-elem.is-sticky').removeClass('is-sticky')
        .removeClass('is-sticky--custom-bottom')
        .css(stickyStyles);
    $('html').removeClass('has-sticky-elem');

    doSticky();
};

module.exports = {
    init() {
        doSticky();

        $(window)
            .on('scroll', doSticky)
            .on('resize', _.throttle(resetSticky, 100));

        //Make sure the sticky bottom is properly adjusted when toggling an accordion without having to scroll
        $('body').on('click', '.js-sticky-elem.is-sticky .js-accordion__target', function() {
            setTimeout(function(j) {
                setStickyBottom($(j).closest('.js-sticky-elem.is-sticky')[0]);
            }, 100, this); //Wait 100ms to try and be sure the accordion has done its thing first
        });
    },
    doSticky: doSticky,
    resetSticky: resetSticky
};

'use strict';

var naPhone = /^(\+1)?\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})(\s*x[0-9]+)?$/;
var regex = {
    phone: {
        us: naPhone,
        ca: naPhone,
        fr: /^0[1-6]{1}(([0-9]{2}){4})|((\s[0-9]{2}){4})|((-[0-9]{2}){4})$/,
        it: /^(([0-9]{2,4})([-\s/]{0,1})([0-9]{4,8}))?$/,
        jp: /^(0\d{1,4}- ?)?\d{1,4}-\d{4}$/,
        cn: /.*/,
        gb: /([^\d]*\d){8}/
    },
    postal: {
        us: /^\d{5}(-\d{4})?$/,
        ca: /([A-Za-z]){1}[0-9]{1}([A-Za-z]){1}\s{1}[0-9]{1}([A-Za-z]){1}[0-9]{1}$/,
        fr: /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/,
        it: /^([0-9]){5}$/,
        jp: /^([0-9]){3}[-]([0-9]){4}$/,
        cn: /^([0-9]){6}$/,
        gb: /^([A-PR-UWYZ0-9][A-HK-Y0-9][AEHMNPRTVXY0-9]?[ABEHMNPRVWXY0-9]? {1,2}[0-9][ABD-HJLN-UW-Z]{2}|GIR 0AA)$/
    },
    personalization: /[\{\}\\\,\:\"]/,
    notCC: /^(?!(([0-9 -]){13,19})).*$/,
    email:/^(([^<>()[\]\\.,;:!#$%&\s@\"]+(\.[^<>()[\]\\.,;:!#$%&\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
};
// global form validator settings
var settings = {
    errorClass: 'error',
    errorElement: 'span',
    onkeyup: false,
    onfocusout: function (element) {
        if (!this.checkable(element)) {
            this.element(element);
        }
    }
};
/**
 * @function
 * @description Validates a given phone number against the countries phone regex
 * @param {String} value The phone number which will be validated
 * @param {String} el The input field
 */
var validatePhone = function (value, el) {
    if(!$('.pt_customer-service, .mcsubscribe-form').length){
        var country = $(el).closest('form').find('.country');
        if (country.length === 0 || country.val().length === 0 || !regex.phone[country.val().toLowerCase()]) {
            return true;
        }
        var rgx = regex.phone[country.val().toLowerCase()];
    }else{
        var rgx = regex.phone['ca'];
    }

    var isOptional = this.optional(el);
    var isValid = rgx.test($.trim(value));

    return isOptional || isValid;
};


var validatePostal = function (value, el) {
    var country = $(el).closest('form').find('.country');
    if (country.length === 0 || country.val().length === 0 || !regex.postal[country.val().toLowerCase()]) {
        return true;
    }

    var rgx = regex.postal[country.val().toLowerCase()];
    var isOptional = this.optional(el);
    var isValid = rgx.test(value);

    return isOptional || isValid;
};

/**
 * @function
 * @description Validates that a credit card owner is not a Credit card number
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validateOwner = function (value) {
    var isValid = regex.notCC.test($.trim(value));
    return isValid;
};

/**
 * @function
 * @description Validates that a email
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
 var validateEmail = function (value) {
   var isValid = regex.email.test($.trim(value));
   return isValid;
};

/**
 * @function
 * @description Validates that a email confirmation
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validateEmailconfirm = function (value) {
    var email = $('.email').val();
    var emailConfirm = $('.emailconfirm').val();
    if(email === emailConfirm){
        return true;
    }else{
        return false;
    }
 };

/**
* Add email validation method to jQuery validation plugin.
* Text fields must have 'email' css class to be validated as email
*/
$.validator.addMethod('email', validateEmail, Resources.VALIDATE_EMAIL);

/**
* Add email validation method to jQuery validation plugin.
* Text fields must have 'email confirmation' css class to be validated as emailconfirm
*/
$.validator.addMethod('emailconfirm', validateEmailconfirm, Resources.VALIDATE_EMAIL);

/**
 * Add phone validation method to jQuery validation plugin.
 * Text fields must have 'phone' css class to be validated as phone
 */
$.validator.addMethod('phone', validatePhone, Resources.INVALID_PHONE);

/**
 * Add phone validation method to jQuery validation plugin.
 * Text fields must have 'postal' css class to be validated as postal
 */
$.validator.addMethod('postal', validatePostal, Resources.INVALID_POSTAL);


/**
 * Add CCOwner validation method to jQuery validation plugin.
 * Text fields must have 'owner' css class to be validated as not a credit card
 */
$.validator.addMethod('owner', validateOwner, Resources.INVALID_OWNER);

/**
 * Add gift cert amount validation method to jQuery validation plugin.
 * Text fields must have 'gift-cert-amont' css class to be validated
 */
$.validator.addMethod('gift-cert-amount', function (value, el) {
    var isOptional = this.optional(el);
    var isValid = (!isNaN(value)) && (parseFloat(value) >= 5) && (parseFloat(value) <= 5000);
    return isOptional || isValid;
}, Resources.GIFT_CERT_AMOUNT_INVALID);

/**
 * Add positive number validation method to jQuery validation plugin.
 * Text fields must have 'positivenumber' css class to be validated as positivenumber
 */
$.validator.addMethod('positivenumber', function (value) {
    if ($.trim(value).length === 0) { return true; }
    return (!isNaN(value) && Number(value) >= 0);
}, ''); // '' should be replaced with error message if needed

/**
 * Validate a group of fields where only one or more is required
 */
$.validator.addMethod('oneormorerequired', function (value, el) {
    const $siblings = $(el).closest('form, fieldset').find('.oneormorerequired');
    let isValid = false;

    $siblings.each((i, el) => {
        isValid = isValid || !!$(el).val().length;
    });

    return isValid;

}, Resources.VALIDATE_ONEORMORE);

/**
 * Validate a personalization text
 */

$.validator.addMethod('personalization-text', function (value, el) {
    return !/[^~!@#$%^&*()_=+[{\]}\\|;:'",<.>/?a-z0-9 -]/ig.test(value.trim());
}, Resources.VALIDATE_PERSONALIZATION_TEXT);


/*
	additional personalization test input validator to prevent chars ",:\{}"
	added regex as a variable to be able to maintain it better in the future
	based on ticket GTN-529
*/
var validatePersonalization = function (value, el) {
    var inValid = regex.personalization.test($.trim(value));
    if(inValid) {
        return false;
    } else {
        return true;
    }
};

$.validator.addMethod('invalid-personalization-input', validatePersonalization, Resources.VALIDATE_PERSONALIZATION_TEXT);


$.extend($.validator.messages, {
    required: Resources.VALIDATE_REQUIRED,
    remote: Resources.VALIDATE_REMOTE,
    email: Resources.VALIDATE_EMAIL,
    url: Resources.VALIDATE_URL,
    date: Resources.VALIDATE_DATE,
    dateISO: Resources.VALIDATE_DATEISO,
    number: Resources.VALIDATE_NUMBER,
    digits: Resources.VALIDATE_DIGITS,
    creditcard: Resources.VALIDATE_CREDITCARD,
    equalTo: Resources.VALIDATE_EQUALTO,
    maxlength: $.validator.format(Resources.VALIDATE_MAXLENGTH),
    minlength: $.validator.format(Resources.VALIDATE_MINLENGTH),
    rangelength: $.validator.format(Resources.VALIDATE_RANGELENGTH),
    range: $.validator.format(Resources.VALIDATE_RANGE),
    max: $.validator.format(Resources.VALIDATE_MAX),
    min: $.validator.format(Resources.VALIDATE_MIN)
});

var validator = {
    regex: regex,
    settings: settings,
    init: function () {
        var self = this;
        $('form:not(.suppress)').each(function () {
            $(this).validate(self.settings);
        });
    },
    initForm: function (f) {
        $(f).validate(this.settings);
    }
};

module.exports = validator;

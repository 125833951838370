'use strict';

var ajax = require('../../ajax'),
    formPrepare = require('./formPrepare'),
    util = require('../../util'),
    shipping = require('./shipping');

/**
 * @function
 * @description Fills the Credit Card form with the passed data-parameter and clears the former cvn input
 * @param {Object} data The Credit Card data (holder, type, masked number, expiration month/year)
 */
function setCCFields(data) {
    var $creditCard = $('[data-method="CREDIT_CARD"]');
    $creditCard.find('input[name$="creditCard_owner"]').val(data.holder).trigger('change');
    $creditCard.find('select[name$="_type"]').val(data.type).trigger('change');
    $creditCard.find('input[name*="_creditCard_number"]').val(data.maskedNumber).trigger('change');
    $creditCard.find('[name$="_month"]').val(data.expirationMonth).trigger('change');
    $creditCard.find('[name$="_year"]').val(data.expirationYear).trigger('change');
    $creditCard.find('input[name$="_cvn"]').val('').trigger('change');
}

/**
 * @function
 * @description Updates the credit card form with the attributes of a given card
 * @param {String} cardID the credit card ID of a given card
 */
function populateCreditCardForm(cardID) {
    // load card details
    var url = util.appendParamToURL(Urls.billingSelectCC, 'creditCardUUID', cardID);
    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data) {
                window.alert(Resources.CC_LOAD_ERROR);
                return false;
            }
            setCCFields(data);
        }
    });
}

/**
 * @function
 * @description Changes the payment method form depending on the passed paymentMethodID
 * @param {String} paymentMethodID the ID of the payment method, to which the payment method form should be changed to
 */
function updatePaymentMethod(paymentMethodID) {
    var $paymentMethods = $('.payment-method');
    $paymentMethods.removeClass('payment-method-expanded');

    var $selectedPaymentMethod = $paymentMethods.filter('[data-method="' + paymentMethodID + '"]');
    if ($selectedPaymentMethod.length === 0) {
        $selectedPaymentMethod = $('[data-method="Custom"]');
    }
    $selectedPaymentMethod.addClass('payment-method-expanded');

    // ensure checkbox of payment method is checked
    $('input[name$="_selectedPaymentMethodID"]').removeAttr('checked');
    $('input[value=' + paymentMethodID + ']').prop('checked', 'checked');

    formPrepare.validateForm();
}

function getPaymentMethod(element){
    var paymentMethod = '';
    if(element.parents('[class^=payment-method]')){
        var paymentValue = $('[name$=selectedPaymentMethodID]').val().toLowerCase();
        switch(paymentValue){
            case 'credit_card':
                paymentMethod = getCreditCardType();
                break;
            case 'paypal':
                paymentMethod = 'pp';
                break;
        }

    }else if(element.parents('.billing-coupon-code')){
        paymentMethod = 'gc';
    }

    return paymentMethod;
}

function getCreditCardType(){
    var cardType = $('[name$=creditCard_type]').val().toLowerCase();
    switch(cardType){
        case 'visa':
            return 'visa';
        case 'master':
            return 'mc';
        case 'amex':
            return 'amex';
        case 'discover':
            return 'disc';
    }
}

function getInteractionType(element){
    var interactionType = false;
    var interactionFieldTypeID = element.attr('id').toLowerCase();

    if(interactionFieldTypeID.indexOf('number') > -1){
        interactionType = 'cn';
    }else if(interactionFieldTypeID.indexOf('month') > -1){
        interactionType = 'em';
    }else if(interactionFieldTypeID.indexOf('year') > -1){
        interactionType = 'ey';
    }else if(interactionFieldTypeID.indexOf('cvn') > -1){
        interactionType = 'cvv';
    }

    return interactionType;
}

/**
 * @function
 * @description loads billing address, Gift Certificates, Coupon and Payment methods
 */
exports.init = function () {
    var $checkoutForm = $('.checkout-billing');
    var $checkoutFormContinue = $('#billingSubmitButton');
    var $continueSelector = $('button[name$="billing_save"]');
    var $addCoupon = $('#add-coupon');
    var $couponCode = $('input[name$="_couponCode"]');
    var $selectPaymentMethod = $('.payment-method-options');
    var selectedPaymentMethod = $selectPaymentMethod.find(':checked').val();

    $('.checkout-billing .input-select.country').on('change',function() {
        var currValue = $(this).val();
        if(currValue === 'GB') {
            $('#dwfrm_billing_billingAddress_addressFields_states_state').closest('.c-form-row.form-row').hide();
        } else {
            $('#dwfrm_billing_billingAddress_addressFields_states_state').closest('.c-form-row.form-row').show();
        }
    });
    formPrepare.init({
        formSelector: 'form[id$="billing"]',
        continueSelector: $continueSelector
    });

    // default payment method to 'CREDIT_CARD'
    updatePaymentMethod((selectedPaymentMethod) ? selectedPaymentMethod : 'CREDIT_CARD');
    $selectPaymentMethod.on('click', 'input[type="radio"]', function () {
        updatePaymentMethod($(this).val());
    });

    // select credit card from list
    $('#creditCardList').on('change', function () {
        var cardUUID = $(this).val();
        if (!cardUUID) {return;}
        populateCreditCardForm(cardUUID);

        // remove server side error
        $('.required.error').removeClass('error');
        $('.error-message').remove();
    });

    $addCoupon.on('click', function (e) {
        e.preventDefault();
        var $error = $checkoutForm.find('.coupon-error'),
            code = $couponCode.val();
        if (code.length === 0) {
            $error.html(Resources.COUPON_CODE_MISSING);
            return;
        }

        var url = util.appendParamsToUrl(Urls.addCoupon, {couponCode: code, format: 'ajax'});
        $.getJSON(url, function (data) {
            var fail = false;
            var msg = '';
            if (!data) {
                msg = Resources.BAD_RESPONSE;
                fail = true;
            } else if (!data.success) {
                msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
                fail = true;
            }
            if (fail) {
                $error.html(msg);
                return;
            }

            //basket check for displaying the payment section, if the adjusted total of the basket is 0 after applying the coupon
            //this will force a page refresh to display the coupon message based on a parameter message
            if (data.success && data.baskettotal === 0) {
                window.location.assign(Urls.billing);
            }
        });
    });

    // trigger events on enter
    $couponCode.on('keydown', function (e) {
        if (e.which === 13) {
            e.preventDefault();
            $addCoupon.click();
        }
    });

    var cancelButtonClick = false;
    $('button.cancel').click(function() {
        cancelButtonClick = true;
    });

    $checkoutFormContinue.on('click', function (e) {
        e.preventDefault();
        if (!$(this).valid()) {
            return true;
        }
        // Commenting as part of GTN-1125 cause it is blocking checkout
        /* if (cancelButtonClick) {
            return true;
        } */
        $checkoutForm.submit();
    });

    $('.billing-coupon-code, .payment-method-options, .payment-section').on('change', 'input, select', function(){
        var dataLayer = window.dataLayer || [];
        var interactionType = getInteractionType($(this));
        var paymentInteraction = {
            event: 'onPayment',
            type: getPaymentMethod($(this)),
            interaction: interactionType
        };
        if(interactionType !== false){
            dataLayer.push(paymentInteraction);
        }
    });

    $('.gift-card-container-btn').on('click',function(e) {
        e.preventDefault();
        var $giftCard = $('.gift-card-div');
        var $gcContainer = $giftCard.find('.gift-card-container');

        if($gcContainer.hasClass('collapse')) {
            $gcContainer.removeClass('collapse').addClass('expanded');
            $(this).find('.fa').removeClass('fa-angle-right').addClass('fa-angle-down');
        }
        else {
            $gcContainer.removeClass('expanded').addClass('collapse');
            $(this).find('.fa').removeClass('fa-angle-down').addClass('fa-angle-right');
        }

    });
    util.initSelectCountry($checkoutForm);
    shipping.updateSummary();
};

'use strict';

const _ = require('lodash');
const dialog = require('../dialog');
const inventory = require('./');
const SessionAttributes = window.SessionAttributes;

var newLine = '\n';
var pdpStoreTemplate = function (store) {
    return [
        '<li class="store-list-item ' + (store.storeId === User.storeId ? ' selected' : '') + '">',
        '    <span class="store-address">' + store.address1 + ', ' + store.city + ' ' + store.stateCode +
        ', ' + store.postalCode + ':&nbsp;' + store.countryCode + '</span>',
        '    <span class="store-status ' + store.statusclass + '" data-status="' + store.statusclass + '">' + store.status + '</span>',
        '</li>'
    ].join(newLine);
};
var pdpStoresListingTemplate = function (stores) {
    if (stores && stores.length) {
        return [
            '<div class="store-list-pdp-container">',
            '    <ul class="store-list-pdp">',
            _.map(stores, pdpStoreTemplate).join(newLine),
            '    </ul>',
            (stores.length > 1 ? '    <button class="stores-toggle collapsed btn btn-link">' + Resources.SEE_MORE + '</button>' : ''),
            '</div>'
        ].join(newLine);
    }
};

var storesListing = function (stores) {
    // list all stores on PDP page
    if ($('.store-list-pdp-container').length) {
        $('.store-list-pdp-container').remove();
    }
    $('.availability-results').append(pdpStoresListingTemplate(stores));

    var pid = getPID();

    if(pid) {
        $('.js-store-availability').load(`${Urls.showStoreAvailability}?pid=${pid}`);
    }
};

var getPID = () => {
    var storePID = $('input[name="pid"]').val();
    if (storePID !== undefined) {
        sessionStorage.setItem('PID', storePID);
    }
};

var productInventory = {
    setPreferredStore: function (storeId) {
        User.storeId = storeId;
        var pid = getPID();

        $.ajax({
            url: Urls.setPreferredStore,
            type: 'POST',
            data: {storeId: storeId, pid: pid, storepickup: 'true'},
            success: function(data) {
                if ($('#QuickViewDialog').length) {
                    location.href = location.href;
                }
                else if (data && data.length && data[0].availability.length) {
                    if (data[0].availability === Resources.IN_STOCK) {
                        $('#add-to-cart').removeAttr('disabled');
                        $('#add-to-cart').prop('disabled', false);
                    } else {
                        $('#add-to-cart').attr('disabled', 'disabled');
                        $('#add-to-cart').prop('disabled', true);
                    }
                }
            }
        });
    },
    productSelectStore: function (postal) {
        var self = this;
        var PID = sessionStorage.getItem('PID');
        //Make sure the PID stored in session storage is up to date before sending ID to retrieve stores if not update it.
        var $jsProductId = $('.js-product-pid');
        if ($jsProductId.length && PID !== $jsProductId.val()) {
            PID = $jsProductId.val();
            sessionStorage.setItem('PID', PID);
        }
        inventory.getStoresInventory(PID, postal).then(function (response) {
            if (response.stores && response.stores.length > 0) {
                inventory.selectStoreDialog({
                    stores: response.stores,
                    query: response.searchKey,
                    selectedStoreId: User.storeId,
                    selectedStoreText: Resources.PREFERRED_STORE,
                    continueCallback: storesListing,
                    selectStoreCallback: self.setPreferredStore,
                    context: 'product'
                });
            } else {
                inventory.zipPrompt(function (postal) {
                    self.productSelectStore(postal);
                }, String.format(Resources.NOSTOREFOUND, SitePreferences.STORE_LOOKUP_DISTANCE));
            }
        }).done();
    },
    initEvents() {
        var self = this;
        getPID();
        $('body').on('click', '.set-preferred-store, #product-content .set-preferred-store, .find-in-store-modal--product .js-change-postal', function (e) {
            e.preventDefault();
            var $tgt = $(e.currentTarget);
            var isQuickLookInStore = $tgt.data('quicklookinstore');
            if (!isQuickLookInStore) {
                if ($tgt.is('.js-change-postal')) {
                    dialog.close();
                    window.updateZip = true;
                }

                // check if we have a preferred store already and aren't updating zip and skip to select store
                if (!User.zip && SessionAttributes.PREFERRED_STORE === null || typeof window.updateZip !== 'undefined' && window.updateZip === true) {
                    inventory.zipPrompt(function (postal) {
                        self.productSelectStore(postal);
                    });
                } else {
                    if ($tgt.data('pid')) {
                        sessionStorage.setItem('PID', $tgt.data('pid'));
                    }
                    self.productSelectStore();
                }
            } else if ($tgt.data('findinstorelink')) {
                window.location.href = $tgt.data('findinstorelink');
            } else {
                var productPageUrl = $('#product-content a.view-full-details').attr('href');
                var updatedPageUrl = productPageUrl + '?quicklookinstore=true';
                window.location.href = updatedPageUrl;
            }
        });

        var isQuickLookRedirect = $('.inventory-availability-wrap').data('quicklookinstore');
        if (isQuickLookRedirect) {
            $('.inventory-availability-wrap').removeData('quicklookinstore');
            $('.set-preferred-store').trigger('click');
        }
    },
    init: function () {
        var $availabilityContainer = $('.availability-results');

        if ($availabilityContainer.length) {
            if (User.storeId) {
                inventory.getStoresInventory(getPID()).then(storesListing);
            }

            // See more or less stores in the listing
            $availabilityContainer.on('click', '.stores-toggle', function (e) {
                e.preventDefault();
                $('.store-list-pdp .store-list-item').toggleClass('visible');
                if ($(this).hasClass('collapsed')) {
                    $(this).text(Resources.SEE_LESS);
                } else {
                    $(this).text(Resources.SEE_MORE);
                }
                $(this).toggleClass('collapsed');
            });
        }
    }
};

module.exports = productInventory;

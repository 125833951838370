'use strict';

var quickview = require('./quickview'),
    _ = require('lodash');

function initQuickViewButtons() {
    $('body').on('mouseenter', '.tiles-container .product-image, .c-product-tile-carousel .js-has-quickview .product-image, .c-product-tile-carousel--3up .js-has-quickview .product-image', function () {
        var $qvButton = $('#quickviewbutton');
        if ($qvButton.length === 0) {
            $qvButton = $('<a id="quickviewbutton" class="quickview">' + Resources.QUICK_VIEW + '</a>');
        }
        var $link = $(this).find('.thumb-link');
        $qvButton.attr({
            'href': $link.attr('href'),
            'title': $link.attr('title')
        }).appendTo(this);
        $qvButton.on('click', function (e) {
            e.preventDefault();
            quickview.show({
                url: $(this).attr('href'),
                source: 'quickview'
            });
        });
    });
}

function gridViewToggle() {
    $('.toggle-grid').on('click', function () {
        $('.search-result-content').toggleClass('wide-tiles');
        $(this).toggleClass('wide');
    });
}

//Separate function so that it can be put off via setTimeout
function swatchMouseLeave(e) {
    // Restore current thumb image
    var $tile = $(this).closest('.product-tile'),
        $thumb = $tile.find('.js-thumb-link__img').eq(0);

    //False alarm.  Swatch list was exited without a swatch ever having been entered
    if(!$thumb.data('has-mouseentered')) {
        return;
    }

    var data = $thumb.attr('data-current');
    if (typeof data === 'string') {
        data = JSON.parse(data);
    }

    if (data.front) {
        $thumb.find('img').attr({
            src: data.front.src,
            alt: data.front.alt,
            title: data.front.title,
            srcset: data.front.srcset
        });
        if ($thumb.is('picture')) {
            $thumb.find('source').attr('srcset', data.front.responsiveSrcset);
        }
    }

    $thumb.data('has-mouseentered', false);  //Now that we're done leaving, reset has-mouseentered for next time
}

/**
 * @private
 * @function
 * @description Initializes events on the product-tile for the following elements:
 * - swatches
 * - thumbnails
 */
function initializeEvents() {
    initQuickViewButtons();
    gridViewToggle();
    $('.swatch-list').on('mouseleave', function() {
        _.debounce(swatchMouseLeave, 100).bind(this)();
    });

    $('.swatch-list .swatch').on('click', function (e) {
        e.preventDefault();
        if ($(this).hasClass('selected')) { return; }

        var $tile = $(this).closest('.product-tile');
        $(this).closest('.swatch-list').find('.swatch.selected').removeClass('selected');
        $(this).addClass('selected');
        $tile.find('.thumb-link').attr('href', $(this).attr('href'));
        $tile.find('name-link').attr('href', $(this).attr('href'));

        var data = $(this).find('.swatch-image').filter(':first').data('thumb');
        var $thumb = $tile.find('.js-thumb-link__img').eq(0);
        var $thumbBack = $tile.find('.js-thumb-link__img--back');
        var currentAttrs = {
            src: data.front.src,
            alt: data.front.alt,
            title: data.front.title,
            srcset: data.front.srcset
        };

        var currentAttrsBack = {
            alt: data.back.alt,
            title: data.back.title,
            src: '', // Clear out src field so new src loads correctly
            srcset: '' // Clear out srcset field so new src loads correctly
        };

        var currentDataBack = {
            src: data.back.src,
            srcset: data.back.srcset
        };
        $thumb.find('img').attr(currentAttrs);
        if ($thumb.is('picture')) {
            currentAttrs.responsiveSrcset = data.front.responsiveSrcset;
            $thumb.find('source').attr('srcset', data.front.responsiveSrcset);
        }

        $thumbBack.find('img').attr('data-src', data.back.src);
        $thumbBack.find('img').attr('data-srcset', data.back.srcset);
        $thumbBack.find('img').attr(currentAttrsBack);

        if ($thumbBack.is('picture')) {
            currentAttrsBack.responsiveSrcset = data.back.responsiveSrcset;
            $thumbBack.find('source').attr('srcset', '');
            $thumbBack.find('source').attr('data-srcset', data.back.responsiveSrcset);
        }

        for(var i in currentDataBack) {
            currentAttrsBack[i] = currentDataBack[i];
        }

        $thumb.attr('data-current', JSON.stringify({
            front: currentAttrs,
            back: currentAttrsBack
        }));
    }).on('mouseenter', function () {
        // get current thumb details
        var $tile = $(this).closest('.product-tile'),
            $thumb = $tile.find('.js-thumb-link__img').eq(0);
        $thumb.data('has-mouseentered', true); //Because swatch-list mouseleave can occur without having entered a swatch, we need to know if a swatch has been entered

        var $thumbBack = $thumb.next(),
            data = $(this).find('picture').filter(':first').find('img').data('thumb'),
            current = $thumb.data('current');

        // If this is the first time, then record the current img

        if (!current) {
            let currentData = {
                front: {
                    src: $thumb.find('img')[0].src,
                    alt: $thumb.find('img')[0].alt,
                    title: $thumb.find('img')[0].title,
                    srcset: $thumb.find('img')[0].srcset,
                    responsiveSrcset: ''
                },
                back: {
                    src: '',
                    alt: '',
                    title: '',
                    srcset: ''
                },
            };
            if ($thumb.is('picture')) {
                currentData.front.responsiveSrcset = $thumb.find('source')[0].srcset;
            }

            if($thumbBack.length && $thumbBack.find('img').length && $thumbBack.find('img')[0]) {
                currentData.back = {
                    src: $thumbBack.find('img').attr('data-src'),
                    alt: $thumbBack.find('img')[0].alt,
                    title: $thumbBack.find('img')[0].title,
                    srcset: $thumbBack.find('img').attr('data-srcset'),
                };

                if ($thumbBack.is('picture')) {
                    currentData.back.responsiveSrcset = $thumbBack.find('source').attr('data-srcset');
                }
            }
            $thumb.attr('data-current', JSON.stringify(currentData));
        }

        // Set the tile image to the values provided on the swatch data attributes
        //Don't need to update back image as user can't be over the swatch and the thumb at the same time
        if (data && 'front' in data) {
            $thumb.find('img').attr({
                src: data.front.src,
                alt: data.front.alt,
                title: data.front.title,
                srcset: data.front.srcset
            });
            if ($thumb.is('picture')) {
                $thumb.find('source').attr('srcset', data.front.responsiveSrcset);
            }
        }

    });

    $('.thumb-link').on('mouseenter', function() {
        let imgs = $(this).find('.js-thumb-link__img'),
            back = imgs.filter('.js-thumb-link__img--back');

        if(!back.length) {
            return;
        }

        var $backSource = back.find('source');
        var $backImg = back.find('img');

        if (!$backImg.attr('src')) {
            $backSource.attr('srcset', $backSource.attr('data-srcset'));
            $backImg.attr({
                src: $backImg.attr('data-src'),
                srcset: $backImg.attr('data-srcset')
            });

            $backImg.on('load', function() {
                $(this).closest('.thumb-link').addClass('thumb-link-active');
            });

        } else {
            $(this).closest('.thumb-link').addClass('thumb-link-active');
        }


    })
    .on('mouseleave', function() {
        let imgs = $(this).find('.js-thumb-link__img'),
            back = imgs.filter('.js-thumb-link__img--back');

        if(!back.length) {
            return;
        }

        $(this).removeClass('thumb-link-active');

    });
}

exports.init = function () {
    initializeEvents();
};

'use strict';

/**
* Google Structured Data for Product Page
*/
function googleProductLoad() {
    var productData = $('#product-data');
    if (productData.length > 0 && productData.val() !== '') {
        productData = JSON.parse(productData.val());
        var e = document.createElement('script');
        e.type = 'application/ld+json';
        var product = {
            '@context': 'http://schema.org/',
            '@type': 'Product',
            '@id': productData.id ? productData.id : '',
            'name': productData.name ? productData.name : '',
            'image': productData.image ? productData.image : '',
            'description': productData.description ? productData.description : '',
            'productID': productData.id ? productData.id : '',
            'brand': {
                '@type': 'Thing',
                'name': productData.brand ? productData.brand : '',
            },
            'offers': {
                '@type': 'Offer',
                'priceCurrency': productData.currency ? productData.currency : '',
                'price': productData.price ? productData.price : '',
                'availability': 'http://schema.org/' + (productData.availability ? productData.availability : ''),
                'seller': {
                    '@type': 'Organization',
                    'name': productData.site ? productData.site : ''
                }
            }
        };
        e.innerHTML = JSON.stringify(product, null, ' ');
        var t = document.getElementsByTagName('script')[0];
        t.parentNode.insertBefore(e, t);
    }
}

module.exports = googleProductLoad;

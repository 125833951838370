'use strict';

/**
 * Detects if scrollbar is in effect and sets class and css var on
 * body element to be used to adjust element widths using vw unit.
 * Source: https://codepen.io/bassplayer7/pen/egZKpm
 */

const handleScrollBar = () => {
    var body = document.querySelector('body');

    if (window.innerWidth > body.clientWidth + 5) {
        body.classList.add('has-scrollbar');
        body.setAttribute('style', '--scroll-bar: ' + (window.innerWidth - body.clientWidth) + 'px');
    } else {
        body.classList.remove('has-scrollbar');
    }

};

module.exports = {
    init() {
        handleScrollBar();
        window.addEventListener('resize', handleScrollBar);
    }
};

'use strict';

const centerScroll = require('../../util').centerScroll;

const ns = 'pdp-image-zoom';

const $html = $('html');
const $zoom = $(`
    <div class="${ns} js-${ns}">
        <div class="${ns}__inner">
            <div class="${ns}__header">
                <div class="${ns}__close js-${ns}__close"></div>
            </div>
            <div class="${ns}__body">
                <img class="${ns}__image">
            </div>
        </div>
    </div>
`);
const $zoomContainer = $zoom.find(`.${ns}__body`);
const $zoomImage = $zoom.find(`.${ns}__image`);

const pdpImageZoom = {
    init() {
        $('body')
        .on('click', `.js-${ns}__target`, e => {
            e.preventDefault();
            let hiresUrl = $(e.currentTarget).attr('href');
            let hiresUrl2x = $(e.currentTarget).data('href-hires');
            let alt = $(e.currentTarget).find('img').attr('alt');
            if (!hiresUrl) {
                let $images = $(e.currentTarget).closest(`.js-${ns}`).find('.js-pdp-sliders__main');
                let $altTarget = $images.find(`.slick-current .js-${ns}__target`);
                if ($altTarget.length === 0) {
                    //  No slick. Single image.
                    $altTarget = $images.find(`.js-${ns}__target`);
                }
                hiresUrl = $altTarget.attr('href');
                hiresUrl2x = $altTarget.data('href-hires');
                alt = $($altTarget).find('img').attr('alt');
            }
            $html.addClass(`is-${ns}-loading`);
            $zoomImage.attr('srcset', `${hiresUrl} 1x, ${hiresUrl2x} 2x`);
            $zoomImage.attr('src', hiresUrl);
            $zoomImage.attr('alt', `${alt} hi-res`);

        })
        .on('click', `.js-${ns}`, e => {
            if ($(e.target).is(`.is-${ns}-active *`) && !$(e.target).is(`.${ns}__image`)) {
                pdpImageZoom.close();
            }
        })
        .append($zoom);

        $zoomImage.load(e => {
            $html.addClass(`is-${ns}-active`).removeClass(`is-${ns}-loading`);
            centerScroll($zoomContainer, $zoomImage);
        });

        $(document).keyup(e => {
            if (e.keyCode === 27) { // esc
                pdpImageZoom.close();
            }
        });

    },

    close() {
        $html.removeClass(`is-${ns}-active`);
        $zoomImage.attr({
            src: '',
            srcset: ''
        });
    }

};

module.exports = pdpImageZoom;

'use strict';

const TPromise = require('promise');
const util = require('./util');
const inventory = require('./storeinventory');
const dialog = require('./dialog');

const getStores = function (postal) {
    return TPromise.resolve($.ajax({
        url: util.appendParamsToUrl(Urls.getStoresByPostal, {
            postal: postal || User.zip
        }),
        dataType: 'json'
    }));
};

const setPreferredStore = function (storeId) {
    User.storeId = storeId;
    $.ajax({
        url: Urls.setPreferredStore,
        type: 'POST',
        data: {storeId: storeId}
    });
};

const selectStoreDialog = function (postal) {
    getStores(postal).then(function (response) {
        if (response.stores && response.stores.length > 0) {
            inventory.selectStoreDialog({
                dialogTitle: Resources.CHOOSE_PREFERRED_STORE,
                stores: response.stores,
                query: response.searchKey,
                selectedStoreId: User.storeId,
                selectedStoreText: Resources.PREFERRED_STORE,
                selectStoreCallback: setPreferredStore,
                context: 'preferred-store'
            });
        } else {
            inventory.zipPrompt(function (postal) {
                self.productSelectStore(postal);
            }, String.format(Resources.NOSTOREFOUND, SitePreferences.STORE_LOOKUP_DISTANCE));
        }

    }).done();
};

const preferredStore = {
    init: function () {
        $('body').on('click', '.js-preferred-store, .find-in-store-modal--preferred-store .js-change-postal', function (e) {
            e.preventDefault();
            var $tgt = $(e.currentTarget);

            if ($tgt.is('.js-change-postal')) {
                dialog.close();
                window.updateZip = true;
            }

            // check if we have a preferred store already and aren't updating zip and skip to select store
            if (!User.zip && window.SessionAttributes.PREFERRED_STORE === null || typeof window.updateZip !== 'undefined' && window.updateZip === true) {
                inventory.zipPrompt(function (postal) {
                    selectStoreDialog(postal);
                });
            } else {
                selectStoreDialog();
            }
        });
    }
};

module.exports = preferredStore;

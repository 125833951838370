'use-strict';


module.exports = {

    init() {
        $('body')
        .on('click', '.js-product-tab a', e => {
            e.stopImmediatePropagation();
            e.preventDefault();

            const currentTarget = $(e.currentTarget);
            const newTab = $(`.js-product-tab a[href="${currentTarget.attr('href')}"]`);
            const newTabContent = $(currentTarget.attr('href'));

            if(!newTabContent.hasClass('is-active-tab-content')) {
                newTab.parents('.js-product-tabs').find('.is-active-tab').removeClass('is-active-tab'); //Remove the active class from the active tab of the current tab group only
                newTabContent.parents('.js-product-info-container').find('.is-active-tab-content').removeClass('is-active-tab-content'); //Remove the active class from the active tab of the current tab group only

                newTab.addClass('is-active-tab');
                newTabContent.addClass('is-active-tab-content');
            }

            //If the link/button isn't part of the product tabs (ie. the user isn't clicking on an actual tab), scroll to the content
            if(!currentTarget.parents('.js-product-tabs').length) {
                //Scroll to the top of the tab by default.
                //Allows for scrolling to a custom target instead if "currentTarget" has a data-target attribute set to a valid element.
                let scrollTarget = currentTarget.data('target') && $(currentTarget.data('target')).length ? $(currentTarget.data('target')) : newTabContent.parent();

                const aScrollTo = require('./a-scroll-to');
                if(aScrollTo && aScrollTo.scrollTo) {
                    //aScrollTo.scrollTo(scrollTarget);
                    $("html, body").animate({ scrollTop: scrollTarget.offset().top - 200});
                }
            }

            //Trigger accordion functionality to open if a non-accordion button corresponding to an accordion is clicked
            if(newTabContent.hasClass('js-accordion__item') && !newTabContent.hasClass('is-expanded') && !currentTarget.hasClass('js-accordion__target')) {
                newTabContent.find('.js-accordion__target').trigger('click');
            }
        });
    }

};

'use strict';

var dialog = require('../../dialog'),
    minicart = require('../../minicart'),
    page = require('../../page'),
    util = require('../../util'),
    Promise = require('promise'),
    _ = require('lodash');

/**
 * @description copy the category ID from the query string to the add to cart AJAX request
 *
 * @returns {String}
 */
function getCategoryID() {
    var qs = document.location.search.substring(1).split('&');
    var cgid = '';
    for (var i=0; i < qs.length; i++) {
        var pair = qs[i].split('=');
        if (decodeURIComponent(pair[0]) === 'cgid') {
            cgid = decodeURIComponent(pair[1]);
        }
    }
    return cgid;
}

/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
var addItemToCart = function (form , e) {
    var $form = $(form),
        $qty = $form.find('input[name="Quantity"]'),
        params;
    if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
        $qty.val('1');
    }
    var url = Urls.addProduct;
    if ($('#pdpMain').find('.inventory').data('instoreonly')) {
        var $storeInfoDiv = $('.c-store-availability');
        if ($storeInfoDiv.length) {
            var storeId = $storeInfoDiv.data('storeid');
            params = {
                'storeId': storeId
            };
            url = util.appendParamsToUrl(url,params);
        }

    }

    return Promise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(url),
        data: $form.serialize() + '&cgid=' + getCategoryID()
    })).then(function (response) {
        // handle error in the response
        if (response.error) {
            throw new Error(response.error);
        } else {
            return response;
        }
    });
};

/**
 * @description Handler to handle the add to cart event
 */
var addToCart = function (e) {
    e.preventDefault();
    var $form = $(this).closest('form');
    // validate form before submitting
    if (!$form.valid()) {
        return;
    }
    $('.reserve-inventory-error').hide();
    $('.availability-msg').hide();
    if ($('.reservedQty-msg').length > 0 && (Number($form.find('input[name="Quantity"]').val()) > Number($('.reservedQty-msg').attr('data-atsqty')))) {
        $('.reserve-inventory-error').show();
        return;
    }
    $('.availability-msg').show();
    $('.reserve-inventory-error').hide();
    addItemToCart($form).then(function (response) {
        var $uuid = $form.find('input[name="uuid"]');
        if ($uuid.length > 0 && $uuid.val().length > 0) {
            page.refresh();
        } else {
            // do not close quickview if adding individual item that is part of product set
            // @TODO should notify the user some other way that the add action has completed successfully
            if (!$(this).hasClass('sub-product-item')) {
                dialog.close();
            } else {
                $(this).closest('.product-add-to-cart').append('<span class="addedMessage">Product added to cart</span>');
            }
            if ($(response).find('.reservedQty-container').length > 0) {
                var resQty = $(response).find('.reservedQty-container').attr('data-resqty');
                var avlQty = $(response).find('.reservedQty-container').attr('data-atsqty');
                var resDiv = $('.reservedQty-msg');
                if (avlQty > -1) {
                    resDiv.attr('data-atsqty', avlQty);
                    resDiv.find('.available-qty-val').text(avlQty);
                }
                resQty > -1 ? resDiv.find('.reserved-qty-val').text(resQty) : '';
            }
            minicart.show(response);
            setTimeout(function () {
                var addedMsg = $('body').find('.addedMessage');
                addedMsg.fadeOut('slow', function () {
                    addedMsg.remove();
                });
            },3000);
        }
    }.bind(this));
};

/**
 * @description Handler to handle the add all items to cart event
 */
var addAllToCart = function (e) {
    e.preventDefault();
    e.stopPropagation();
    var $productForms = $('#product-set-list').find('form').toArray();
    var parsedResults = [];
    var tempDom = $('<result>'); // initializing a DOM element just to append the HTML result and then be able to use jQuery selectors on it. It works even if the DOM element is invalid, like <result>
    var miniCartQtyArray = [];
    Promise.all(_.map($productForms, addItemToCart))
        .then(function (responses) {
            responses.forEach(function (r) {
                parsedResults.push($.parseHTML(r)); // parsing the HTML result
            });

            parsedResults.forEach(function(pr, i) {
                tempDom.append(pr); // appending the parsed HTML to a DOM element
                miniCartQtyArray.push(parseInt($('.minicart-quantity', tempDom).html())); // searching for the minicart quantity element inside all the HTML responses
                tempDom = $('<result>'); // returning the tempDom to its initial state
            });

            dialog.close();
            // show the final response only, which would include all the other items
            let finalResponse = responses[miniCartQtyArray.indexOf(Math.max(...miniCartQtyArray))];
            if (finalResponse) {
                // add class to style the dialog to show multiple items in a scrollable area on desktop
                finalResponse = finalResponse.replace('class="mini-cart-content', 'class="mini-cart-content multi-items-added');

                minicart.show(finalResponse); // Using the index of the highest minicart quantity instead of just the last one
            }
        });
};

/**
 * @function
 * @description Binds the click event to a given target for the add-to-cart handling
 */
module.exports = {
    initEvents() {
        $('.product-detail .add-to-cart').on('click', addToCart);
        $('#add-all-to-cart').off().on('click', addAllToCart);
        this.update();
    },

    update() {
        $('.add-to-cart[disabled]').attr('title', $('.availability-msg').text());
    }
};

'use strict';

var account = require('./account'),
    bonusProductsView = require('../bonus-products-view'),
    quickview = require('../quickview'),
    progress = require('../progress'),
    dialog = require('../dialog'),
    cartStoreInventory = require('../storeinventory/cart'),
    page = require('../page'),
    minicart = require('../minicart');

/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */
function initializeEvents() {
    $('.same-day-delivery-zip').on("keyup", function (e) {
        var zipCodeValue = $(this).val();
        var pid = $(this).attr("data-pid");
        if (zipCodeValue.length === 7 || zipCodeValue.length === 6) {
            var regex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
            var regexZIPcode = new RegExp(regex);
            var regexCheck = regexZIPcode.test(zipCodeValue);
            if (regexCheck) {
                $("#same-day-delivery-btn-"+pid).prop("disabled", false);
            }else {
                $("#same-day-delivery-btn-"+pid).prop("disabled", true);
            }
        } else {
            $("#same-day-delivery-btn-"+pid).prop("disabled", true);
        }

    });

    $('button[id*=applyprice]').on('click', function (e) {
        e.preventDefault();
        var reason, uuid, standardPrice, unitPrice, url, pid, isSupervisorAgent=false;
        pid = $(this).closest('.oob-table').data('pid');
        isSupervisorAgent = $(this).closest('.oob-table').data('isSupervisorAgent');
        uuid = this.name.split('-')[1];
        if ($('#select-reason-'+uuid).val() === "") {
            $('#select-reason-'+uuid).removeClass('valid');
            $('#select-reason-'+uuid).addClass('invalid');
            $('#select-reason-'+uuid).attr('aria-invalid', 'true');
            reason = "";
            return;
        } else {
            $('#select-reason-'+uuid).removeClass('invalid');
            $('#select-reason-'+uuid).addClass('valid');
            $('#select-reason-'+uuid).attr('aria-invalid', 'false');
            reason = $('#select-reason-'+uuid).val();
        }
        if ($('#unitprice-'+uuid).val() === "") {
            unitPrice = $('#unitprice-'+uuid).attr('max');
            standardPrice = $('#unitprice-'+uuid).data('standardPrice');
        } else {
            $('#unitprice-'+uuid).removeClass('invalid');
            $('#unitprice-'+uuid).addClass('valid');
            $('#unitprice-'+uuid).attr('aria-invalid', 'false');
            unitPrice = $('#unitprice-'+uuid).val();
            standardPrice = $('#unitprice-'+uuid).data('standardPrice');
        }
        url = this.dataset.url;
        $.ajax(url, {
            type:'POST',
            data: {
                pid: pid,
                uuid: uuid,
                reason: reason,
                unitPrice: unitPrice,
                standardPrice: standardPrice,
                isSupervisorAgent: isSupervisorAgent
            },
            dataType: 'json',
            success:function(response){
                if (!response.success) {
                    alert(response.message);
                    progress.hide();
                    reinit();
                } else {
                    progress.hide();
                    page.redirect(Urls.cartShow);
                }
            },
            error: function (e) {
                var temp = e;
                alert("There was some problem with the request. Please try again");
                progress.hide();
                reinit();
            }
        });
    });
    $('select[id*=select-reason]').on('change', function(){
        var new_selection = $(this).find('option:selected');
        $(this).find('option').not(new_selection).removeAttr('selected');
        new_selection.attr("selected",true);
        $(this).removeClass('invalid');
    });
    $('#cart-table').on('click', '.item-edit-details a', function (e) {
        e.preventDefault();
        quickview.show({
            url: e.target.href,
            source: 'cart'
        });
    });
    $('#cart-table, .bonus-item-promo').on('click', '.bonus-item-actions a, .item-details .bonusproducts a, .bonus-button-update a', function (e) {
        e.preventDefault();
        var $this = $(this);
        bonusProductsView.show(this.href);
        console.log($this);
        setTimeout(function() {
            var relatedProductId = document.querySelector('#relatedProductId');
            relatedProductId.value = $this.attr('data-related');
            if($this.hasClass('update-bonus')) {
                var bonusProductAction = document.querySelector('#bonusProductAction');
                bonusProductAction.value = "bonuschange";
            }
        },3000);
    });

    // override enter key for coupon code entry
    $('form input[name$="_couponCode"]').on('keydown', function (e) {
        if (e.which === 13 && $(this).val().length === 0) { return false; }
    });

    $('form input[name$="_couponCode"]').on('change', function (e) {
        var dataLayer = window.dataLayer || [];
        dataLayer.push({
            event: 'onPromo',
            code: $(this).val(),
            id: $(this).attr('id')
        });
    });
    initCouponCheckBox($('.cart-order-totals'));

    //to prevent multiple submissions of the form when removing a product from the cart
    var removeItemEvent = false;
    $('button[name$="deleteProduct"]').on('click', function (e) {
        if (removeItemEvent) {
            e.preventDefault();
        } else {
            removeItemEvent = true;
        }
    });

    var removeFromCartDialogOpen = false;
    function removeFromCartDialog(pid, uuid, prevQuantity) {
        $('.product-remove-confirm-modal').on('click', '.cancel-button, .close-button', function() {
            dialog.close();
            return false;
        });
        dialog.open({
            html: '<div>' + Resources.REMOVE_PRODUCT_CONFIRM + '</div>',
            options: {
                dialogClass: 'product-remove-confirm-modal ui-dialog--relax',
                title: Resources.REMOVE_PRODUCT_TITLE,
                buttons: [{
                    text: Resources.REMOVE_PRODUCT_OK,
                    class: 'btn btn-primary btn-lg btn-block',
                    click: function () {
                        changeProductInventory(pid, uuid, '0', prevQuantity);
                    }
                }],
                open: function () {
                    removeFromCartDialogOpen = true;
                    //Need to merge buttonpane into container for layout to display and scroll properly
                    var buttonPane = $('.ui-dialog-buttonpane');
                    buttonPane.detach();
                    $('#dialog-container').append(buttonPane);
                    dialog.dialogOpen();
                }, close: function () {
                    removeFromCartDialogOpen = false;
                    document.body.style.setProperty('overflow','auto');
                    $(this).dialog('close');
                    // clear dialog content.
                    // Stops videos and such from playing in the background.
                    $(this).html('');
                }
            }
        });
    }
    // override enter key for quantity entry if quantity is 0
    $('.quantity input').on('keydown', function (e) {
        if (e.which === 13) {
            var quantity = $(this).val();
            if (quantity === '0' && !removeFromCartDialogOpen) {
                e.preventDefault();
                var pid = $(this).parent().siblings('.update-cart').data('pid');
                var uuid = $(this).parent().siblings('.update-cart').data('uuid');
                var prevQuantity = $(this).parent().siblings('.update-cart').data('quantity');
                removeFromCartDialog(pid, uuid, prevQuantity);
                return false;
            }
        }
    });
    $('.quantity input').on('change', function(e) {
        var pid = $(this).parent().siblings('.update-cart').data('pid');
        var uuid = $(this).parent().siblings('.update-cart').data('uuid');
        var quantity = $(this).val();
        var prevQuantity = $(this).parent().siblings('.update-cart').data('quantity');
        if(quantity === '0') {
            e.preventDefault();
            if (!removeFromCartDialogOpen) {
                removeFromCartDialog(pid, uuid, prevQuantity);
            }
        } else {
            changeProductInventory(pid, uuid, quantity, prevQuantity);
        }
    });
    $('.item-user-actions .remove-from-cart').on('click', function(e) {
        e.preventDefault();
        var $cartRowQuantityUpdateCart = $(this).closest('.cart-row').find('.item-quantity .update-cart');
        var pid = $cartRowQuantityUpdateCart.data('pid');
        var uuid = $cartRowQuantityUpdateCart.data('uuid');
        var prevQuantity = $cartRowQuantityUpdateCart.data('quantity');

        removeFromCartDialog(pid, uuid, prevQuantity);
    });
}
function initCouponCheckBox($parentDiv) {
    var $coupon = $parentDiv.find('.coupon-code');
    var infoMsg = $coupon.find('.error');
    $parentDiv.find('#checkbox-show-coupon').on('click',function(e){
        if($coupon.hasClass('is-hidden')) {
            $coupon.removeClass('is-hidden').addClass('is-active');
        }
        else {
            $coupon.removeClass('is-active').addClass('is-hidden');
        }
    });

    if($('.coupon-line-items').length > 0 || infoMsg.length) {
        $parentDiv.find('#checkbox-show-coupon').trigger('click');
        $coupon.removeClass('is-hidden').addClass('is-active');
    }

}

/**
* @private
* @function
* @description Initialize additional functionality related with PayPal integration
*/
function initLowCostCartProduct() {
    $(document).on('click','.cart-add-product',function(e) {
        var pid = $('#cart-add-product-pid').val();
        var inventory = parseInt($('#cart-add-product-inventory').val());
        var currentInventory = parseInt($('#cart-add-product-currentquantity').val());
        var totalInventory = inventory + currentInventory;
        var uuid = $('#cart-add-product-uuid').val();
        addProductInventory(pid,totalInventory,uuid);
    });
}

/**
* @private
* @function
* @description Initialize additional functionality related with PayPal integration
*/
function initPaypalFunctionality() {
    var dialog = require('../dialog');
    var $expressButton = $('.js_paypal_start_ba_checkout');

    $expressButton.on('click', function() {
        if($expressButton.data('is-address-exist') === true) {
            return true;
        }
        dialog.open({
            url: $expressButton.data('edit-address-url'),
            options: {
                title: $expressButton.data('edit-address-title'),
                open: initEditDefaultShippingAddressForm
            },
        });
        return false;
    });

    function initEditDefaultShippingAddressForm() {
        var $form = $('#paypalEditDefaultShippingAddress');
        $form.on('click', '.apply-button', function() {
            if (!$form.valid()) {
                return false;
            }
            var applyName = $form.find('.apply-button').attr('name');
            var options = {
                url: $form.attr('action'),
                data: $form.serialize() + '&' + applyName + '=x',
                type: 'POST'
            };
            $.ajax(options).done(function(data) {
                if (typeof(data) !== 'string') {
                    if(data.success) {
                        dialog.close();
                        window.location = $expressButton.attr('href');
                    } else {
                        window.alert(data.message);
                        return false;
                    }
                } else {
                    $('#dialog-container').html(data);
                    initEditDefaultShippingAddressForm();
                }
            });
            return false;
        });
        $form.on('click', '.cancel-button, .close-button', function() {
            dialog.close();
            return false;
        });
        $('#paypalSelectSavedAddress').change(function() {
            var data = $(this).val();
            try {
                data = JSON.parse(data);

                for(var addressName in data) {
                    var val = data[addressName];
                    if(typeof val === 'string') {
                        val = val.replace(/\^/g,"'");
                    }
                    $('#dwfrm_profile_address_' + addressName).val(val);
                }
            } catch(e) {
                $form.find('input:text').val('');
                $form.find('select').val('');
            }
        });
    }
}

function reinit() {
    initializeEvents();
    if (SitePreferences.STORE_PICKUP) {
        cartStoreInventory.init();
    }
    account.initCartLogin();
    initPaypalFunctionality();
}

function changeProductInventory(pid, uuid, quantity, prevQuantity) {
    if (quantity === '0') {
        dialog.close();
    }
    var $cartitems = $('#cart-table');
    // indicate progress
    progress.show($cartitems);

    // call controller Cart.updateCartQuantity
    $.ajax(Urls.updateProductsInCart, {
        type:'POST',
        data: {
            pid: pid,
            uuid: uuid,
            quantity: quantity,
            prevQuantity: prevQuantity
        },
        dataType: 'html',
        success:function(response){
            var cartcontent = $('<div />').append(response).find('#cart-items-box').html();
            var minicartcontent = $('<div />').append(response).find('#mini-cart-box').html();
            $("#primary").html(cartcontent);
            $("#mini-cart").html(minicartcontent);
            minicart.init();
            progress.hide();
            reinit();
            window.$payPalSG();

        },
        error: function (e) {
            alert("There was some problem with the request. Please try again");
            progress.hide();
            reinit();
        }
    });
}

function addProductInventory(pid, quantity, uuid) {
    progress.show($('#cart-table'));
    var options;
    if (uuid !== 'NA') {
        options = {
            pid: pid,
            Quantity: quantity,
            uuid: uuid
        };
    } else {
        options = {
            pid: pid,
            Quantity: quantity
        };
    }
    $.ajax(Urls.addProduct, {
        type:'POST',
        data: options,
        success:function(response){
            progress.hide();
            page.redirect(Urls.cartShow);
        },
        error: function (e) {
            alert("There was some problem with the request. Please try again");
        }
    });
}

exports.init = function () {
    initializeEvents();
    if (SitePreferences.STORE_PICKUP) {
        cartStoreInventory.init();
    }
    account.initCartLogin();
    initPaypalFunctionality();
    initLowCostCartProduct();
};

exports.initCouponCheckBox = initCouponCheckBox;

'use strict';

var moment = require('moment');
var momentDurationFormatSetup = require("moment-duration-format");

momentDurationFormatSetup(moment);

var ns = 'js-daily-deal-countdown';

module.exports = {
    init() {
        var $counter = $(`.${ns}`);
        var start = $counter.text().trim();
        var startParts = start.split(':');
        var duration = moment.duration({
            seconds: startParts[2],
            minutes: startParts[1],
            hours: startParts[0],
        });

        setInterval(() => {
            $counter.text(duration.subtract(1, 'seconds').format('hh:mm:ss'));
        }, 1000);

    }
};

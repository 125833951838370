'use strict';

var ajax = require('./ajax'),
    util = require('./util');
/**
 * @function
 * @description Load details to a given gift certificate
 * @param {String} id The ID of the gift certificate
 * @param {Function} callback A function to called
 */
exports.checkBalance = function (id, ean, csrf, billingEmail, callback) {
    // load gift certificate details
    var url = util.appendParamsToURL(Urls.giftCardCheckBalance, {
        'giftCertificateID': id,
        'giftCertificateEAN': ean,
        'csrf_token': csrf,
        'billing_email': billingEmail});

    ajax.getJson({
        url: url,
        callback: callback
    });
};

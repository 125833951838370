'use strict';

/**
 * For any custom functionality using jQueryUI that doesn't belong anywhere else
 */

function datepickerInit () {
    if ($('.js-datepicker').length) {
        $('.js-datepicker').find('input').datepicker();
    }

    $.datepicker.regional.fr = {
        prevText: 'Préc',
        nextText: 'Proch.',
        monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août','Septembre', 'Octobre', 'Novembre', 'Décembre'],
        monthNamesShort: ['led', 'úno', 'bře', 'dub', 'kvě', 'čer', 'čvc', 'srp', 'zář', 'říj', 'lis', 'pro'],
        dayNamesShort: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
        dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S']
    };

    if ($('html').attr('lang') === 'fr') {
        $.datepicker.setDefaults($.datepicker.regional.fr);
    }

}

module.exports = {
    methods: {
        datepickerInit: datepickerInit
    },
    init() {
        datepickerInit();
    }
};

'use strict';

/**
 * Custom js for the front end of daily deals for products
 */

var dialog = require('../../dialog');

function dailyDealDialog() {
    const $signup = $('.js-daily-deal-signup');

    if ($signup.length) {
        $signup.on('click', function (e) {
            e.preventDefault();

            var dialogHeight = $(this).data('dialog-height') || 'auto';
            var dialogWidth = $(this).data('dialog-width') || 'auto';
            var dialogTitle = $(this).data('dialog-title');

            var dialogConfig = {
                options: {
                    dialogClass: 'c-daily-deal__signup',
                    height: dialogHeight,
                    width: dialogWidth
                },
                url: $(this).attr('href')
            };

            if (dialogTitle) {
                dialogConfig.options.title = dialogTitle;
            }

            dialogConfig.callback = handleSubmit;
            dialog.open(dialogConfig);
        });
    }
}

function handleSubmit() {
    $('.mcdailydeal-form').submit(function( event ) {
        event.preventDefault();

        var $form = $(this);

        if ($form.valid()) {
            var subscribeAction = $form.find('.js-daily-deal-signup').attr('name');
            var formData = $form.serialize() + '&' + subscribeAction;
            $.ajax({
                url: $form.attr('action'),
                data: formData,
                method: $form.attr('method')
            })
           .done(function (data) {
               if (data.success) {
                   $('.mcdailydeal-form-container').addClass('d-none');
                   $('.mcdailydeal-success-message').removeClass('d-none');
               }else{
                   $('.mcdailydeal-form-container').addClass('d-none');
                   $('.mcdailydeal-error-message').removeClass('d-none');
               }
           })
           .fail(function (xhr, textStatus) {
               $('.mcdailydeal-form-container').addClass('d-none');
               $('.mcdailydeal-error-message').removeClass('d-none');
           });
        }

    });

    $('.mcdailydeal-close-button').on('click', function (e) {
        dialog.close();
    });

    $('.mcdailydeal-try-again-button').on('click', function (e) {
        e.preventDefault();
        $('.mcdailydeal-form-container').removeClass('d-none');
        $('.mcdailydeal-error-message').addClass('d-none');
    });
}

module.exports = {
    init() {
        dailyDealDialog();
        handleSubmit();
    }
};

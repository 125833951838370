/* global addressComplete */
'use strict';

var ajax = require('../../ajax'),
    formPrepare = require('./formPrepare'),
    progress = require('../../progress'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
    minicart = require('../../minicart'),
    dialog = require('../../dialog'),
    page = require('../../page'),
    cart = require('../cart');

var shippingMethods;

/**
 * @function
 * @description Initializes gift message box, if shipment is gift
 */
function giftMessageBox() {
    // show gift message box, if shipment is gift
    return true;
    //$('.gift-message-text').toggleClass('hidden', $('input[name$="_shippingAddress_isGift"]:checked').val() !== 'true');
}

/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
function updateSummary() {
    var $summary = $('#secondary.summary');
    // indicate progress
    progress.show($summary);

    // load the updated summary area
    $summary.load(Urls.summaryRefreshURL, function () {
        // hide edit shipping method link
        $summary.fadeIn('fast');
        $summary.find('.checkout-mini-cart .minishipment .header a').hide();
        $summary.find('.order-totals-table .order-shipping .label a').hide();
        minicart.init();
        var $coupon = $summary.find('.coupon-code');

        cart.initCouponCheckBox($summary);

        $coupon.find('#add-coupon').on('click',function(e) {
            e.preventDefault();
            var $message = $summary.find('.coupon-message');
            var couponCode = $('input[name*="couponCode"').val();
            progress.show($(this));
            if (couponCode.length === 0) {
                $message.html(Resources.COUPON_CODE_MISSING).addClass('error').removeClass('success');
                progress.hide();
                return;
            }

            var url = util.appendParamsToUrl(Urls.addCoupon,{'couponCode': couponCode,'format':'ajax'});
            ajax.getJson({
                url: url,
                callback: function (data) {
                    var fail = false;
                    var msg = '';
                    if (!data) {
                        msg = Resources.BAD_RESPONSE;
                        fail = true;
                    } else if (!data.success) {
                        msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
                        fail = true;
                    }
                    if (fail) {
                        $message.html(msg).addClass('error').removeClass('success');
                        return;
                    }
                    else {
                        if(data.status ==='APPLIED') {
                            updateSummary();
                            var promise = $.get(Urls.summaryRefreshURL);
                            $(function() {
                                promise.done(function() {
                                    $summary.find('.coupon-message').html(data.message).addClass('success').removeClass('error');
                                });
                            });
                        }
                        else {
                            $message.html(data.message).addClass('error').removeClass('success');
                        }
                    }
                    progress.hide();
                }
            });
        });

        $coupon.find('button[name*="deleteCoupon"]').on('click',function(e) {
            e.preventDefault();
            var $this = $(this);
            var couponCode = $this.parent().attr('id');
            var url = util.appendParamsToUrl(Urls.removeCoupon,{'couponCode': couponCode,'format':'ajax'});
            progress.show($this);
            ajax.getJson({
                url: url,
                callback: function (data) {
                    progress.hide();
                    if(!data) {
                        alert('Could not remove Coupon');
                        return;
                    }
                    else {
                        if(data.success) {
                            updateSummary();
                        }
                    }
                }
            });
        });
    });
}

/**
 * @function
 * @description Helper method which constructs a URL for an AJAX request using the
 * entered address information as URL request parameters.
 */
function getShippingMethodURL(url, extraParams) {
    var $form = $('.address');
    var params = {
        address1: $form.find('input[name$="_address1"]').val(),
        address2: $form.find('input[name$="_address2"]').val(),
        countryCode: $form.find('select[id$="_country"]').val(),
        stateCode: $form.find('select[id$="_state"]').val(),
        postalCode: $form.find('input[name$="_postal"]').val(),
        city: $form.find('input[name$="_city"]').val()
    };
    return util.appendParamsToUrl(url, $.extend(params, extraParams));
}

/**
 * @function
 * @description selects a shipping method for the default shipment and updates the summary section on the right hand side
 * @param
 */
function selectShippingMethod(shippingMethodID) {
    // nothing entered
    if (!shippingMethodID) {
        return;
    }
    // attempt to set shipping method
    var url = getShippingMethodURL(Urls.selectShippingMethodsList, {shippingMethodID: shippingMethodID});
    ajax.getJson({
        url: url,
        callback: function (data) {
            updateSummary();
            if (!data || !data.shippingMethodID) {
                window.alert('Couldn\'t select shipping method.');
                return false;
            }
            // display promotion in UI and update the summary section,
            // if some promotions were applied
            $('.shippingpromotions').empty();

        }
    });
}

/**
 * @function
 * @description Make an AJAX request to the server to retrieve the list of applicable shipping methods
 * based on the merchandise in the cart and the currently entered shipping address
 * (the address may be only partially entered).  If the list of applicable shipping methods
 * has changed because new address information has been entered, then issue another AJAX
 * request which updates the currently selected shipping method (if needed) and also updates
 * the UI.
 */
function updateShippingMethodList() {
    var $shippingMethodList = $('#shipping-method-list');
    if (!$shippingMethodList || $shippingMethodList.length === 0) { return; }
    /**
     * Check if address is PO Box
     */
    var $form = $('.address');
    var addressString = $form.find('input[name$="_address1"]').val() + ' ' + $form.find('input[name$="_address2"]').val();
    var regex = /(?:P(?:ost(?:al)?)?[.\-\s]*(?:(?:O(?:ffice)?[.\-\s]*)?B(?:ox|in|\b|\d)|o(?:ffice|\b)(?:[-\s]*\d)|code)|box[-\s\b]*\d)/i;
    var regexPOBox = RegExp(regex);
    var regexCheck = regexPOBox.test(addressString);
    var isPOBox = {'ispobox': false};

    if (regexCheck) {
        isPOBox = {'ispobox': true};
    }

    var url = getShippingMethodURL(Urls.shippingMethodsJSON, isPOBox);

    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data) {
                window.alert('Couldn\'t get list of applicable shipping methods.');
                return false;
            }
            if (shippingMethods && shippingMethods.toString() === data.toString()) {
                // No need to update the UI.  The list has not changed.
                return true;
            }

            // We need to update the UI.  The list has changed.
            // Cache the array of returned shipping methods.
            shippingMethods = data;
            // indicate progress
            progress.show($shippingMethodList);
            // load the shipping method form
            var smlUrl = getShippingMethodURL(Urls.shippingMethodsList, isPOBox);
            $shippingMethodList.load(smlUrl, function () {
                $shippingMethodList.fadeIn('fast');
                // rebind the radio buttons onclick function to a handler.
                $shippingMethodList.on('click','.input-radio',function(e) {
                    selectShippingMethod($(this).val());
                });

                // update the summary
                updateSummary();
                progress.hide();
                tooltip.init();
                // if nothing is selected in the shipping methods select the first one
                if ($shippingMethodList.find('.input-radio:checked').length === 0) {
                    $shippingMethodList.find('.input-radio:first').prop('checked', 'checked');
                }
            });
        }
    });
}

/**
 * @function
 * @description Render a Google map with marker for each CanadaPost location
 *
 */
/*eslint-disable */
function initCanadaPostMap() {
    var canadaPostDiv = $('#canadapost-xml');
    var canadaPostMap = document.getElementById('canadapost-map');

    if (canadaPostMap && canadaPostDiv.length) {
        var canadaPostXML = $('#canadapost-xml').html();
        var address, name, link, lat, long, map, title;
        var Mississauga = new google.maps.LatLng(43.577, -79.634);

        // Create a map object
        map = new google.maps.Map(canadaPostMap, {
            // Use Mississauga as center of map
            center: Mississauga,
            zoom: 8
        });
        /* returned object looks like:
         * <post-office-list>
         *  <post-office>
         *      <address/>
         *      <city/>
         *      <latitude/>
         *      <longitude/>
         *      <postal-code/>
         *      <name/>
         *      <office-id/>
         *      <link/>
         *  </post-office>
         * </post-office-list>
         */
        var locations = $.parseXML(canadaPostXML),
            $xml = $( locations );

        $.each($xml.find('post-office'), function(index,item) {
            name = $(this).find('name').text();
            lat = $(this).find('latitude').text();
            long = $(this).find('longitude').text();
            address = $(this).find('address').text();
            link = $(this).find('link').attr('href');
            title = name + '\n ' + address;

            // Create a marker and set its position
            var marker = new google.maps.Marker({
                map: map,
                position: {lat: parseFloat(lat), lng: parseFloat(long)},
                title: title
            });
            //Re-center map on nearest coordinates
            if (index === 0) {
                map.setCenter({lat: parseFloat(lat), lng: parseFloat(long)});
            }

            // Render a radio button for each store on the template
            $('.js-canada-post-location-list').append('<label class="canada-post-location-result"><input class="canada-post-location-result__radio js-cp-storefront" type="radio" name="storefront" value="' + link + '" >' + title + '</label>');
        });

    } else{
        // Don't do anything, the request isn't ready.
        return false;
    }
}
/*eslint-enable */

exports.init = function () {
    if(sessionStorage.getItem('doordash') == 'true' && !$(".doordashTipSummary").hasClass('d-none')){
        var updatedSubtotal = parseFloat($('.order-subtotal-val').data('subtotal')) - parseFloat($('.doordashTipAmountAdded').data('tip'));
        updatedSubtotal = parseFloat(updatedSubtotal).toFixed(2)
        setTimeout(function () {
            var activeLocale =$('.locale-select .is-active').text();
            if(activeLocale.includes("FR")){
                $('.order-subtotal-val').text(updatedSubtotal + " C$");  
            }else {
                $('.order-subtotal-val').text("$"+ updatedSubtotal);
            }
        }, 1000);
    } 

    $(".doordashTip").click(function (e) {
        $(".doordashTip").removeClass("active");
        $(this).addClass("active");
        e.preventDefault();
        var tipAmount = $(this).attr("data-tip-val");
        if (tipAmount != "other") {
            addTipAjaxCall(tipAmount);
        }else{
             $(".doordash-other-tip").removeClass("d-none");
        }
    });

    $('.doordashother-tipamount-btn').on('click', function (e) {
        addTipAjaxCall(parseFloat($("#doordash-other-tipamount").val()).toFixed(2));
    });

    function addTipAjaxCall(tipAmount) {
        $(".doordash-other-tip").addClass("d-none");
        var url = $('#adddoordashTips').val();
        $.ajax(url, {
            type: 'GET',
            data: {
                tipAmount: tipAmount
            },
            dataType: 'json',
            success: function (response) {
                $('.doordashTipSummary').removeClass('d-none');
                var activeLocale = $('.locale-select .is-active').text();
                if (activeLocale.includes("FR")) {
                    $('#doordashTipAmountAdded').text(tipAmount + " C$");
                } else {
                    $('#doordashTipAmountAdded').text('$' + tipAmount);
                }
                $('#doordashTipAmountAdded').attr('data-tip',tipAmount)
            },
            error: function (e) {
            }
        });
    }


    var $continueSelector = $('button[name$="shippingAddress_save"]');
    var $checkoutForm = $('.checkout-shipping');
    formPrepare.init({
        continueSelector: $continueSelector,
        formSelector: '[id$="singleshipping_shippingAddress"]'
    });
    $('input[name$="_shippingAddress_isGift"]').on('click', giftMessageBox);

    $('.address').on('change',
        'input[name$="_addressFields_address1"], input[name$="_addressFields_address2"], select[name$="_addressFields_states_state"], input[name$="_addressFields_city"], input[name$="_addressFields_zip"]',
        updateShippingMethodList
    );

    $('.js-radio-formselect').on('change', function(){
        if ( $('.js-radio-formselect:checked').val() === 'standard'){
            $('.js-radio-form-standard').show();
            $('.js-radio-form-shiptocanada').hide();
        } else{
            $('.js-radio-form-standard').hide();
            $('.js-radio-form-shiptocanada').show();
        }
    });

    giftMessageBox();
    updateShippingMethodList();
    initCanadaPostMap();
    util.initSelectCountry($checkoutForm);

    // Canada Post contact infor form
    var $CPContactForm = $('#contact-address').closest('form');
    if ($CPContactForm.length) {
        util.initSelectCountry($CPContactForm);
    }

    $continueSelector.on('click', function (e) {
        e.preventDefault();
        if ($('select[name$="shippingAddress_addressFields_country"]').val() === 'US') {
            $.ajax({
                dataType: 'json',
                url: Urls.shippingEligibiliy
            })
            .done(function (response) {
                //if there are ineligible products, render a dialog with those
                // otherwise, submit the form
                if (response.ineligible) {
                    dialog.open({
                        url: Urls.ineligibleShipping,
                        options: {
                            dialogClass: 'ui-dialog--relax'
                        }
                    });
                } else {
                    if (sessionStorage.getItem('doordashSelectedZip') && (sessionStorage.getItem('doordashSelectedZip').toLowerCase() != $('.shipping-postal').val().replace(/ /g, "").toLowerCase()) && sessionStorage.getItem('doordash') === 'true') {
                        $('.text-danger').text($('#doordash-shipping-postal-mismatch').val());
                        $('html, body').animate({
                            scrollTop: 0
                        }, 300);

                    } else {
                        $checkoutForm.submit();
                    }
                }
            });
        } else {
            if (sessionStorage.getItem('doordashSelectedZip') &&  (sessionStorage.getItem('doordashSelectedZip').toLowerCase() != $('.shipping-postal').val().replace(/ /g, "").toLowerCase()) && sessionStorage.getItem('doordash') === 'true') {
                $('.text-danger').text($('#doordash-shipping-postal-mismatch').val());
                $('html, body').animate({
                    scrollTop: 0
                }, 300);
            } else {
                $checkoutForm.submit();
            }
        }
    });

    if(typeof addressComplete !== 'undefined' && addressComplete) {
        addressComplete.controls[0].listen("populate", function (address) {
            updateShippingMethodList();
        });
    }
};

exports.updateShippingMethodList = updateShippingMethodList;
exports.initCanadaPostMap = initCanadaPostMap;
exports.updateSummary = updateSummary;

'use strict';

var ajax = require('../../ajax'),
    tooltip = require('../../tooltip'),
    util = require('../../util');
require('intersection-observer');

module.exports = function () {
    var $addToCart = $('#add-to-cart'),
        $addAllToCart = $('#add-all-to-cart'),
        $productSetList = $('#product-set-list');

    var updateAddToCartButtons = function () {
        if ($productSetList.find('.add-to-cart[disabled]').length > 0) {
            $addAllToCart.attr('disabled', 'disabled');
            // product set does not have an add-to-cart button, but product bundle does
            $addToCart.attr('disabled', 'disabled');
        } else {
            $addAllToCart.removeAttr('disabled');
            $addToCart.removeAttr('disabled');
        }
    };

    if ($productSetList.length > 0) {
        updateAddToCartButtons();
    }
    // click on swatch for product set
    $productSetList.on('click', '.product-set-item .swatchanchor', function (e) {
        e.preventDefault();
        if ($(this).parents('li').hasClass('unselectable')) { return; }
        var url = Urls.getSetItem + this.search;
        var $container = $(this).closest('.product-set-item');
        var qty = $container.find('form input[name="Quantity"]').first().val();

        ajax.load({
            url: util.appendParamToURL(url, 'Quantity', isNaN(qty) ? '1' : qty),
            target: $container,
            callback: function () {
                updateAddToCartButtons();
                tooltip.init();
            }
        });
    });

    $productSetList.on('change', '.product-set-item .variation-select', function (e) {
        e.preventDefault();
        if ($(this).parents('li').hasClass('unselectable')) { return; }

        var search=this.value.substring(this.value.indexOf('?pid'));
        var url = Urls.getSetItem+search;
        var $container = $(this).closest('.product-set-item');
        var qty = $container.find('form input[name="Quantity"]').first().val();

        ajax.load({
            url: util.appendParamToURL(url, 'Quantity', isNaN(qty) ? '1' : qty),
            target: $container,
            callback: function () {
                updateAddToCartButtons();
                tooltip.init();
            }
        });
    });

    // scroll to product set item
    $('.product-set-item-nav-button').on('click', function (e) {
        // $('.product-set-item-nav-button').removeClass('active');
        let $activeButton = $(this);
        // $activeButton.addClass('active');
        let $target = $($activeButton.data('target'));
        let targetPos = $target.offset().top + $target.parent().scrollTop() - $target.offsetParent().offset().top;
        $target.parent().animate({
            scrollTop: targetPos
        }, 500);
    });

    // mark nav button as active when product set item is in view
    function productSetProductObserver(entries, io) {
        for (var i = 0; i < entries.length; i++) {
            var entry = entries[i];
            if (entry.intersectionRatio <= 0.5)  {
                continue;
            }
            let $target = $('.product-set-item-nav-button[data-target="#' + entry.target.id + '"]');
            if ($target.length) {
                $('.product-set-item-nav-button').removeClass('active');
                $target.addClass('active');
            }
        }
    }

    var pspIO = new IntersectionObserver(productSetProductObserver, {
        threshold: 0.5
    });
    pspIO.POLL_INTERVAL = 1000;

    var $productSetProducts = $('.product-set-item');
    for (let i=0; i < $productSetProducts.length; i++) {
        let $target = $productSetProducts[i];
        pspIO.observe($target);
    }

    // When the quantity changes via the custom quantity control in variations content, update the hidden real quantity field within the form
    $('.custom-quantity-control input').on('change', function (e) {
        let $customQuantityControl = $(this);
        $customQuantityControl.parents('.product-set-details-row').find('.product-add-to-cart form input[name="Quantity"]').val($customQuantityControl.val());
    });
};

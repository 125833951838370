'use strict';

require('slick-carousel');

var bonusProductsView = require('./bonus-products-view'),
    reserveinventory = require('./reserveinventory'),
    ajax = require('./ajax');

var timer = {
    id: null,
    clear: function () {
        if (this.id) {
            window.clearTimeout(this.id);
            delete this.id;
        }
    },
    start: function (duration, callback) {
        this.id = setTimeout(callback, duration);
    }
};

var minicart = {
    init: function () {
        this.$el = $('#mini-cart');
        this.$content = this.$el.find('.mini-cart-content');
        reserveinventory.init();
        // events
        this.$el.find('.mini-cart-total').on('mouseenter', function () {
            if (this.$content.not(':visible')) {
                this.slide();
            }
        }.bind(this))
        .on('mouseleave', () => {
            timer.clear();
            timer.start(800, this.close.bind(this));
        });

        this.$content.on('mouseenter', function () {
            timer.clear();
        }).on('mouseleave', function () {
            timer.clear();
            if(!this.$content.hasClass('minicart-desktop-action')) {
                timer.start(30, this.close.bind(this));
            }
        }.bind(this));

        this.$content.find('.close-btn').on('click',function() {
            timer.clear();
            timer.start(30, this.close.bind(this));
        }.bind(this));

        /* GTN-604: Function to close when user clicks off modal */
        // $(document).on('click',function(event) {
        //     if (!$(event.target).closest("#mini-cart").hasClass('is-open')) {
        //         timer.clear();
        //         timer.start(30, minicart.close.bind(minicart));
        //     }
        // });

        // Commenting the checkout button for GTN-1131 - As we are navigatiing user to cart page now
        // $('#proceedCheckout-btn').on('click', function (e) {//Delete DOordash session to avoid the postal code check on the shipping page
        //     sessionStorage.removeItem("doordash");
        //     sessionStorage.removeItem("doordashSelectedZip");
        // });
    },
    /**
     * @function
     * @description Shows the given content in the mini cart
     * @param {String} A HTML string with the content which will be shown
     */
    show: function (html, isRefresh) {
        this.$el.html(html);
        this.init();
        this.slide();

        // if this is a refresh of an existing minicart, skip loadBonusOption
        // because a refresh is triggered after closing an updated bonus product list
        if (!isRefresh) {
            bonusProductsView.loadBonusOption();
        }
        var bonusProductMessage = document.querySelector('#bonusProductMessage');
        $(document).find('.mini-cart-bonus-product-added span').text(bonusProductMessage.value);
        if(bonusProductMessage.value) {
            $(document).find('.mini-cart-bonus-product-added').addClass('has-message');   
        }
        $('html').addClass('is-minicart-active');
        if(window.innerWidth >= 768) {
            this.addDesktopActions();
        }
        else if(window.innerWidth < 768) {
            $('.mini-cart-close-btn').show();
            $('.close-btn.checkout-btn').show();
        }
    },
    /**
     * @function
     * @description Slides down and show the contents of the mini cart
     */
    slide: function () {
        timer.clear();
        // show the item
        this.$content.slideDown('slow');
        this.$el.addClass('is-open');
        // after a time out automatically close it
        //timer.start(6000, this.close.bind(this));
    },
    /**
     * @function
     * @description Closes the mini cart with given delay
     * @param {Number} delay The delay in milliseconds
     */
    close: function (delay) {
        timer.clear();
        this.$content.slideUp(delay, function() {
            minicart.removeDesktopActions();
            if($('.mini-cart-close-btn:visible').length===1) {
                $('.mini-cart-close-btn').hide();
                $('.close-btn.checkout-btn').hide();
            }
        });
        this.$el.removeClass('is-open');
        $('html').removeClass('is-minicart-active');
    },
    addDesktopActions: function () {
        $('.mini-cart-content').addClass('minicart-desktop-action');
        $('.mini-cart-close-btn').show();
        $('.close-btn.checkout-btn').show();
        $('.mini-cart-product-added').show();
        $('.minicart-recommendations').show();
    },
    removeDesktopActions: function () {
        $('.mini-cart-content').removeClass('minicart-desktop-action');
        $('.mini-cart-close-btn').hide();
        $('.close-btn.checkout-btn').hide();
        $('.mini-cart-product-added').hide();
        $('.minicart-recommendations').remove();
    },
    refreshMinicart: function () {
        var thisMinicart = this;
        ajax.load({
            url: Urls.minicart,
            callback: function (response) {
                thisMinicart.show(response, true);
            }
        });
    }
};

module.exports = minicart;

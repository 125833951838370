'use strict';

var inventory = require('./'),
    ajax = require('../ajax'),
    progress = require('../progress'),
    cart = require('../pages/cart');

var cartInventory = {
    setSelectedStore: function (storeId) {
        var $selectedStore = $('.store-tile[data-store-id=' + storeId + ']'),
            $lineItem = $('.cart-row[data-uuid="' + this.uuid + '"]'),
            storeAddress = $selectedStore.find('.store-address').html(),
            storeStatus = $selectedStore.find('.store-status').data('status'),
            storeStatusText = $selectedStore.find('.store-status').text();
        this.selectedStore = storeId;

        $lineItem.find('.instore-delivery .selected-store-address')
            .data('storeId', storeId)
            .attr('data-store-id', storeId)
            .html(storeAddress);
        $lineItem.find('.instore-delivery .selected-store-availability')
            .data('status', storeStatus)
            .attr('data-status', storeStatus)
            .text(storeStatusText);
        $lineItem.find('.instore-delivery .delivery-option').removeAttr('disabled').trigger('click');
    },
    cartSelectStore: function (selectedStore) {
        var self = this;
        inventory.getStoresInventory(this.uuid).then(function (response) {
            inventory.selectStoreDialog({
                stores: response.stores,
                query: response.searchKey,
                selectedStoreId: selectedStore,
                selectedStoreText: Resources.SELECTED_STORE,
                continueCallback: function () {},
                selectStoreCallback: self.setSelectedStore.bind(self)
            });
        }).done();
    },
    setDeliveryOption: function (value, storeId) {
        // set loading state
        $('.item-delivery-options')
            .addClass('loading')
            .children().hide();
            
            var data = {
            plid: this.uuid,
            storepickup: (value === 'store' ? true : false)
        };
        if (value === 'store') {
            data.storepickup = true;
            data.storeid = storeId;
        } else if (value === 'doordash') {
            data.doordashpickup = true;
            data.storeid = storeId;
        } else {
            data.storepickup = false;
        }
        data.doordashSelected = value === 'doordash' ? true :false;
        ajax.getJson({
            url: Urls.setStorePickup,
            data: data,
            callback: function () {
                // remove loading state
                $('.item-delivery-options')
                    .removeClass('loading')
                    .children().show();
                progress.show($('#primary'));
                ajax.load({
                    url:Urls.cartShow,
                    data: data,
                    target: $('#primary'),
                    callback: function () {
                        cartInventory.init();
                        cart.init();
                        progress.hide();
                        window.$payPalSG();
                    }
                });
            }
        });
    },
    init: function () {
        var self = this;
        $('.item-delivery-options .set-preferred-store').on('click', function (e) {
            e.preventDefault();
            self.uuid = $(this).data('uuid');
            var selectedStore = $(this).closest('.instore-delivery').find('.selected-store-address').data('storeId');
            if (!User.zip) {
                inventory.zipPrompt(function () {
                    self.cartSelectStore(selectedStore);
                });
            } else {
                self.cartSelectStore(selectedStore);
            }
        });
        $('.item-delivery-options .delivery-option').on('click', function () {
            if ($(this).val() == "doordash") {
                $(".doordash-delivery-zip-"+$(this).data('pid')).removeClass("d-none");
            } else {
                // reset the uuid
                var selectedStore = $(this).closest('.instore-delivery').find('.selected-store-address').data('storeId');
                self.uuid = $(this).closest('.cart-row').data('uuid');
                self.setDeliveryOption($(this).val(), selectedStore);
                sessionStorage.removeItem("doordash");
                sessionStorage.removeItem("doordashSelectedZip");
                sessionStorage.removeItem("doordashSelectedZipEligible");   
            }
        });

         $('.same-day-delivery-btn').on('click', function (e) {
            e.preventDefault();
            $('.same-day-delivery-btn').prop('disabled', true);
            var url = $('#findSameDayDeliveryStore').val();
            var pid = $(this).attr("data-pid");
            var zipCode = $('#same-day-delivery-zip-' + pid).val().replace(/ /g, "");
             $.ajax(url, {
                 type: 'GET',
                 data: {
                     zipCode: zipCode,
                     pid: pid
                 },
                 dataType: 'json',
                 success: function (response) {
                     if (response.success) {
                        sessionStorage.setItem('doordash', true);
                        sessionStorage.setItem('doordashSelectedZip', zipCode);
                        sessionStorage.setItem('doordashSelectedZipEligible', true);

                        $(".doordash-delivery-success").removeClass("d-none");
                         $(".doordash-delivery-no-success").addClass("d-none");
                         self.setDeliveryOption('doordash', response.selectedStoreId);
                         $('.checkout-button-submit').prop('disabled', false);
                         $('.same-day-delivery-btn').prop('disabled', true);

                     } else {
                         sessionStorage.setItem('doordash', false);
                         sessionStorage.setItem('doordashSelectedZipEligible', false);
                         $(".doordash-delivery-no-success").removeClass("d-none");
                         $(".doordash-delivery-success").addClass("d-none");
                         $('.checkout-button-submit').prop('disabled', true);
                         $('.same-day-delivery-btn').prop('disabled', false);
                     }
                 },
                 error: function (e) {

                 }
             });
        });

        $('body').on('click', '.js-change-postal', function() {
            var selectedStore = $(this).closest('.instore-delivery').find('.selected-store-address').data('storeId');
            inventory.zipPrompt(function () {
                self.cartSelectStore(selectedStore);
            });
        });
    }
};

module.exports = cartInventory;

'use strict';

let $locale, $timerWrapper, $counter, countdownInterval;

function reserveinventoryTimer() {
    if (!($counter.length && $timerWrapper.length && $timerWrapper.attr('data-reserveinventory-expiry-time'))) {
        return;
    }

    let now = Date.now();
    let timeoutTime = Number($timerWrapper.attr('data-reserveinventory-expiry-time')) || 0;
    if (now >= timeoutTime) {
        $timerWrapper.addClass('reserveinventory-timer--expired');

        clearInterval(countdownInterval);
        return;
    }
    let timeRemaining = new Date(timeoutTime - now);
    if ($locale.length && String($locale.text()).indexOf('FR') > -1) {
        $counter.html(Resources.RESERVEINVENTORY_LESSTHAN + " " + timeRemaining.getMinutes() + Resources.RESERVEINVENTORY_MINUTES + " " + timeRemaining.getSeconds() + Resources.RESERVEINVENTORY_SECONDS);
    } else {
        $counter.html(timeRemaining.getMinutes() + Resources.RESERVEINVENTORY_MINUTES + " " + timeRemaining.getSeconds() + Resources.RESERVEINVENTORY_SECONDS);
    }
}

module.exports = {
    init() {
        $timerWrapper = $('.reserveinventory-timer');
        $counter = $('.reserveinventory-expiry__counter');
        $locale = $('.locale-select .is-active');
        if (!($counter.length && $timerWrapper.length && $timerWrapper.attr('data-reserveinventory-expiry-time'))) {
            return;
        }
        reserveinventoryTimer($timerWrapper, $counter);
        $timerWrapper.css('display', 'block');
        countdownInterval = setInterval(reserveinventoryTimer, 1000);
    }
};

'use strict';

var dialog = require('../../dialog'),
    productStoreInventory = require('../../storeinventory/product'),
    util = require('../../util'),
    addToCart = require('./addToCart'),
    availability = require('./availability'),
    image = require('./image'),
    productSet = require('./productSet'),
    variant = require('./variant'),
    pdpGiftcard = require('./giftcard'),
    googleStructuredProduct = require('./googlestructuredproduct'),
    personalization = require('./personalization'),
    dailyDeal = require('./dailyDeal');

/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */

/***
 * @description Moves product data tabs in line with add-to-cart button
 */
function alignPDPContent(){
    if(window.innerWidth > 767){
        try {
            var element = document.getElementById('add-to-cart');
            if (element && element.length) {
                var rect = element.getBoundingClientRect();
                var buttonLeft = rect.left;
                document.getElementsByClassName('js-product-tabs')[0].style.left = buttonLeft;
            }
        } catch (e) {
            // console.log(e);
        }
        try {
            let $pdpMainImage = $('.js-pdp-sliders__main').find('.primary-image');
            let $pdpBelowProductdetailsWrapper = $('.js-pdp-below-productdetails-wrapper');
            if ($pdpMainImage.length && $pdpBelowProductdetailsWrapper.length) {
                let carouselHeight = $pdpMainImage.innerHeight() + 100;
                $pdpBelowProductdetailsWrapper.css('top', carouselHeight + 'px');
                $('.pdp-main-container').css('min-height', ($pdpBelowProductdetailsWrapper.innerHeight() + carouselHeight + 75) + 'px');
            }
        } catch (e) {
            // console.log(e);
        }
    }
}

function initializeDom() {
    alignPDPContent();

    let $pdpBelowProductdetailsWrapper = $('.js-pdp-below-productdetails-wrapper');
    if ($pdpBelowProductdetailsWrapper.length) {
        var slickInitObserver = new MutationObserver(function (event) {
            if (event[0].target.className.includes('slick-initialized')) {
                slickInitObserver.disconnect();
                alignPDPContent();
            }
        });
        slickInitObserver.observe($pdpBelowProductdetailsWrapper[0], {attributes: false, childList: true, characterData: false, subtree:true});
    }
}

/**
 * @description Initialize event handlers on product detail page
 */
function initializeEvents() {
    var $pdpMain = $('#pdpMain');

    addToCart.initEvents();
    availability();
    variant.init();
    image();
    pdpGiftcard();
    productSet();
    productStoreInventory.init();
    productStoreInventory.initEvents();
    personalization.init();
    dailyDeal.init();

    // Add to Wishlist and Add to Gift Registry links behaviors
    $pdpMain.on('click', '[data-action="wishlist"], [data-action="gift-registry"]', function () {
        var data = util.getQueryStringParams($('.pdpForm').serialize());
        if (data.cartAction) {
            delete data.cartAction;
        }
        var url = util.appendParamsToUrl(this.href, data);
        this.setAttribute('href', url);
    });

    // product options
    $pdpMain.on('change', '.product-options select', function () {
        var salesPrice = $pdpMain.find('.product-add-to-cart .price-sales');
        var selectedItem = $(this).children().filter(':selected').first();
        salesPrice.text(selectedItem.data('combined'));
    });

    // prevent default behavior of thumbnail link and add this Button
    $pdpMain.on('click', '.thumbnail-link, .unselectable a', function (e) {
        e.preventDefault();
    });

    $('.size-chart-link.modal a').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href')
        });
    });

    $(window).on('resize', function(){
        alignPDPContent();
    });

    googleStructuredProduct();

}


var product = {
    initializeEvents: initializeEvents,
    init: function () {
        initializeDom();
        initializeEvents();
        $('.js-has-quickview').removeClass('js-has-quickview'); //Essentially override quickview being enabled on the 4 tile carousel
    }
};

module.exports = product;

'use strict';

var util = require('../../util');

function initializeEvents() {

    util.initSelectCountry($('#RegistrationForm'));
}

function showSameDayDeliveryMessage() {
    const now = new Date();
    const hour = now.getHours();

    if (hour < 15) {// Less than 3 PM
        $('#SameDayDeliveryBefore2Pm').removeClass('d-none');
    } else {
        $('#SameDayDeliveryAfter2Pm').removeClass('d-none');
    }

    if(sessionStorage.getItem('doordash') == 'true' && $('.checkout-shipping').length <= 0 && !$(".doordashTipSummary").hasClass('d-none')){
        var updatedSubtotal = parseFloat($('.order-subtotal-val').data('subtotal')) - parseFloat($('.doordashTipAmountAdded').data('tip'));
        updatedSubtotal = parseFloat(updatedSubtotal).toFixed(2)
        setTimeout(function () {
            var activeLocale =$('.locale-select .is-active').text();
            if(activeLocale.includes("FR")){
                $('.order-subtotal-val').text(updatedSubtotal + " C$"); 
                $('#doordashTipAmountAdded').text($('#doordashTipAmountAdded').text()+ " C$");
            }else {
                $('#doordashTipAmountAdded').text('$'+$('#doordashTipAmountAdded').text().replace(/\s/g, ''));
                $('.order-subtotal-val').text("$"+ updatedSubtotal);
            }
        }, 1000);
    }   
    if(sessionStorage.getItem('doordash') == 'true'){
        //clear the DD session after succesfull order placement
        sessionStorage.removeItem("doordash");
        sessionStorage.removeItem("doordashSelectedZip");
    }
}
var orderconfirmation = {
    init: function () {
        initializeEvents();
        showSameDayDeliveryMessage();
    }
};

module.exports = orderconfirmation;
'use strict';

var _ = require('lodash');

var ns = 'js-accordion';

function setJsHeight() {
    $(`.${ns}__body--js-height`).each(function(i, j) {
        let elemHeight = 0;

        //If the element has a cutoff, the content's current height may not match its full height
        //So get content inside of elems directly inside accordion body
        if($(j).hasClass('has-cutoff')) {
            $(j).children().each(function(k, l) {
                elemHeight += l.scrollHeight;
            });
        }
        else {
            elemHeight = $(j)[0].scrollHeight;
        }

        elemHeight += $(j).outerHeight() - $(j).height();  //Get total size of vertical paddings and add them to max height

        //Check if placeholder is computed to check for var support
        if(getComputedStyle(document.body).getPropertyValue('--elem-height')) {
            j.style.setProperty('--elem-height', elemHeight + 'px');
        }
        //Fallback if css variable not supported
        else {
            $(j).css('max-height', elemHeight + 'px');
        }
    });
}

module.exports = {

    init() {

        $('body')
        .on('click', `.${ns}__target`, e => {
            if (!$(e.target).is('a')) {
                e.preventDefault();
                const $tgt = $(e.currentTarget);
                const $tgtItem = $tgt.closest(`.${ns}__item`);

                $tgtItem.toggleClass('is-expanded');
                $tgt.closest(`.${ns}`).find(`.${ns}__item`).not($tgtItem).removeClass('is-expanded');
            }
        })
        .on('click', `.${ns}__see-all`, e => {
            const $tgt = $(e.currentTarget);

            $tgt.closest('.c-accordion__body').toggleClass('c-accordion__see-all');
        });

        setJsHeight();
        $(window).on('resize', _.throttle(e => {
            setJsHeight();
        }, 100));
    },
    setJsHeight: setJsHeight
};

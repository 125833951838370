'use strict';

var ajax = require('../../ajax'),
    image = require('./image'),
    progress = require('../../progress'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
    addToCart = require('./addToCart');

/**
 * @description update product content with new variant from href, load new content to #product-content panel
 * @param {String} href - url of the new product variant
 **/
var updateContent = function (href) {
    var $pdpForm = $('.pdpForm');
    var productID = $('.js-product-pid').length ? $('.js-product-pid').val() : '';
    var productName = $('.js-product-brand').length ? $('.js-product-brand').data("itemname") : '';
    var brand = $('.js-product-brand').length ? $('.js-product-brand').data("brandname") : '';
    var qty = $pdpForm.find('input[name="Quantity"]').first().val();
    var $QuickViewDialog = $('#QuickViewDialog');
    var params = {
        Quantity: isNaN(qty) ? '1' : qty,
        format: 'ajax',
        productlistid: $pdpForm.find('input[name="productlistid"]').first().val()
    };
    if($('.pt_product-details').length && $('.pt_product-details').data('page_template')) {
        params.pageTemplate = $('.pt_product-details').data('page_template');
    }
    if ($QuickViewDialog.length) {
        params.source = 'quickview';
    }

    progress.show($('#pdpMain'));

    ajax.load({
        url: util.appendParamsToUrl(href, params),
        target: $('#product-content'),
        callback: function(response, textStatus, responseText) {
            $('.product-variations-current-url').attr('data-current-url',href);
            addToCart.update();
            image.replaceImages();
            $(window).trigger( 'productChanged' , [response, textStatus, responseText]);
            tooltip.init();

            //Clear addthis so that share menu will be reinitialized when content is reloaded via ajax
            if(window.addthis) {
                window.addthis = null;
            }

            image.replaceImages();
            tooltip.init();

            // Write out the SmarterHQ data into the dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "variationChange",
                "pageView": "product",
                "productId": productID,
                "productName": productName,
                "brand": brand
            });
            validateMissingVariant();

            // Power reviews
            $( ".p-w-r" ).remove(); // Removing the elemtn from DOM - As we are rendering PR on every variant click
            document.dispatchEvent(new Event('renderPR'));

            addToCart.initEvents();
        }
    });
};

var validateMissingVariant = function() {
    var $variations = $('.product-variations');
    var missingVariants = 0;
    var $variantAttributes = $variations.find('.attribute');
    var variantName = 'Styles';
    for(var x=0;x<$variantAttributes.length;x++) {
        var $variantValue = $variantAttributes.eq(x).find('.has-selected');
        if($variantValue.length === 0) {
            variantName = $variantAttributes.eq(x).find('.label').eq(0).html();
            missingVariants+=1;
        }
    }

    if(missingVariants === 1) {
        $('.availability-novariation').html(
            Resources.SELECT_FOR_AVAILABILITY.replace('{0}', variantName)
        );
    }
};

function reloadProductContent() {
    var $selectedAttr= $('.product-variations').find('.has-selected');
    var href;
    if ($selectedAttr.length) {
        if ($selectedAttr.length) {
            $selectedAttr = $selectedAttr.eq(0);
            if ($selectedAttr.is('option')) {
                href = $selectedAttr.val();
            }
            else {
                href = $selectedAttr.find('a').attr('data-original-url');
            }
        }
    }


    if (href) {
        updateContent(href);
    }
    else {
        progress.show($('#primary'));
        var pid = $('#pid').val();
        var url = util.appendParamToURL(Urls.getProductUrl,'pid',pid);
        ajax.load({
            url:url,
            target: $('#primary'),
            callback: function () {
                progress.hide();
                addToCart.initEvents();
            }
        });
    }
}


module.exports = {
    init:  function () {
        var $pdpMain = $('#pdpMain');
        // hover on swatch - should update main image with swatch image
        /*
         * Amplience pg. 13 of documentation (v.17.2.1) comment block
        $pdpMain.on('mouseenter mouseleave', '.swatchanchor', function () {
            var largeImg = $(this).data('lgimg'),
                $imgZoom = $pdpMain.find('.main-image'),
                $mainImage = $pdpMain.find('.primary-image');

            if (!largeImg) { return; }
            // store the old data from main image for mouseleave handler
            $(this).data('lgimg', {
                hires: $imgZoom.attr('href'),
                url: $mainImage.attr('src'),
                alt: $mainImage.attr('alt'),
                title: $mainImage.attr('title')
            });
            // set the main image
            image.setMainImage(largeImg);
        });
        */

        // click on swatch - should replace product content with new variant
        $pdpMain.on('click', '.product-detail .swatchanchor', function (e) {
            e.preventDefault();
            if ($(this).parents('li').hasClass('unselectable')) { return; }
            updateContent(this.href);
        });

        // change drop down variation attribute - should replace product content with new variant
        $pdpMain.on('change', '.variation-select', function () {
            if ($(this).val().length === 0) { return; }
            updateContent($(this).val());
        });

        // click on reset - should reset product content
        $pdpMain.on('click', 'a.variations-reset-link', function (e) {
            e.preventDefault();
            updateContent(this.href);
        });
    },
    reloadProductContent: reloadProductContent
};

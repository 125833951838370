'use strict';

const viewportIs = require('./viewport-is');

module.exports = {
    init() {
        $('#navigation').on('click', '.has-sub-menu > a', e => {
            if (viewportIs(['mobile', 'tablet'])) {
                e.preventDefault();
                e.stopPropagation();
                $(e.currentTarget).parent().toggleClass('is-active');
            }
        });
    }
};

/**
 * Google Tag Manager Events
 */

'use strict';

require('intersection-observer');
var _ = require('lodash');
var $ = require('jquery');

// ensure datalayer is initialized
window.dataLayer = window.dataLayer || [];

var io;
var observedImpressions = [];
var reportedImpressions = [];

/**
 * Collect as much information regarding the product element (producttile)
 * as possible for impression and click stream data
 */
function getProductElementInfo(element) {
    var $el = $(element);
    var id = $el.attr('data-itemid');
    var name = $el.attr('data-itemname');
    var position = null;
    var list = "";
    var category = "";
    var brand = $el.attr('data-brandname');

    // if we have a gridindex on the element or parent container
    // use that as position
    var $posEl = $el.closest('[data-gridindex]');
    if ($posEl.length) {
        position = parseInt($posEl.attr('data-gridindex'), 10) + 1;
    }
    var $listEl = $el.closest('[data-gridname]');
    if ($listEl.length) {
        list = $listEl.attr('data-gridname');
    }
    var $catEl = $el.closest('[data-categoryname]');
    if ($catEl.length) {
        category = $catEl.attr('data-categoryname');
    }

    return {
        'name' : name,
        'id' : id,
        'list' : list,
        'category' : category,
        'brand' : brand,
        'position' : position
    }
}

/**
 * GTM Data Layer Reporter
 */
function _reporter(target, cb) {
    if (!target && observedImpressions.length === 0) {
        return;
    }

    var dataLayer = window.dataLayer;
    var _dataLayer = {
        'ecommerce' : {}
    };

    _dataLayer.event = "impressions";
    if (target) {
        var _ev = {};
        // target action event override
        _dataLayer.event = "productClick";

        var productClicked = getProductElementInfo(target);

        if (productClicked.list) {
            _ev.actionField = {
                'list' : productClicked.list
            }
        }

        _ev.products = [productClicked];

        _dataLayer.ecommerce.click = _ev;
    }

    if (observedImpressions.length > 0) {
        var impressions = _.map(observedImpressions, function(element) {
            return getProductElementInfo(element);
        });

        Array.prototype.push.apply(reportedImpressions, observedImpressions);
        observedImpressions = [];
        _dataLayer.ecommerce.impressions = impressions;
    }

    if (cb) {
        _dataLayer.eventCallback = cb;
    }

    dataLayer.push(_dataLayer);
}
var reporter = _.debounce(_reporter, 3000);


function observer(entries, io) {

    for (var i = 0; i < entries.length; i++){
        var entry = entries[i];
        if (entry.intersectionRatio <= 0)  {
            continue;
        }
        io.unobserve(entry.target);

        if (reportedImpressions.indexOf(entry.target) === -1 &&
            observedImpressions.indexOf(entry.target) === -1) {
            observedImpressions.push(entry.target);
        }
    }

    reporter();
}

/**
 * Call to reinitialize tag manager events such as when
 * new products are loaded on the grid
 */
function reinit() {
    var i;

    // product impression observers
    var productTiles = document.querySelectorAll('.product-tile');
    for (i=0; i < productTiles.length; i++) {
        var tile = productTiles[i];
        io.observe(tile);
    }

    // product click. use off() to avoid duplicate productClick GTM events
    $(productTiles).off('click', '', clickHandler)
    $(productTiles).on('click', '', clickHandler)
}

function clickHandler(ev) {
    var href = $(ev.target).closest('a').attr('href');
    if (href) {
        if ($(ev.target).has('.quickview')) {
            _reporter(this);
        } else {
            ev.preventDefault();
            _reporter(this, function() {
                document.location = href;
            });
        }
    }
}

function init() {
    // poll for product tile impressions
    io = new IntersectionObserver(observer, {
        threshold: 0.5
    });
    io.POLL_INTERVAL = 1000;

    reinit();

    window.addEventListener('unload', function() {
        // flush immediately
        _reporter();
    });
}


exports.init = init;
exports.reinit = reinit;

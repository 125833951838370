'use strict';
var qs = require('qs');
var url = require('url');
var _ = require('lodash');
var viewportIs = require('../../viewport-is');
require('slick-carousel');
var imageZoom = require('./image-zoom');

var mobileBreakpoint = getComputedStyle(document.body).getPropertyValue('--breakpoint-lg');
mobileBreakpoint = mobileBreakpoint ? mobileBreakpoint.trim().substr(0, mobileBreakpoint.trim().length - 2) : 1024; //Backup for older browsers



/**
 * @description Sets the main image attributes and the href for the surrounding <a> tag
 * @param {Object} atts Object with url, alt, title and hires properties
 */
function setMainImage (atts) {
    /**$('#pdpMain .primary-image').attr({
        src: atts.url,
        alt: atts.alt,
        title: atts.title
    });*/
    updatePinButton(atts.url);


}

function updatePinButton (imageUrl) {
    var pinButton = document.querySelector('.share-icon[data-share=pinterest]');
    if (!pinButton) {
        return;
    }
    var newUrl = imageUrl;
    var primaryImage = document.querySelector('#pdpMain .primary-image');
    if (!imageUrl) {
        newUrl = primaryImage !== null ? primaryImage.getAttribute('src') :  '';
    }
    var href = url.parse(pinButton.href);
    var query = qs.parse(href.query);
    query.media = url.resolve(window.location.href, newUrl);
    query.url = window.location.href;
    var newHref = url.format(_.extend({}, href, {
        query: query, // query is only used if search is absent
        search: qs.stringify(query)
    }));
    pinButton.href = newHref;
}

/**
* @description Initialize Amplience Dynamic Images. */
var initAmpDI = function () {
    var amp = window.amp = window.amp || {};
    if ('dwInit' in amp && typeof amp.dwInit == 'function') {
        amp.dwInit.call(amp);
    }
};

/**
 * @description Replaces the images in the image container, for eg. when a different color was clicked.
 */
function replaceImages () {
    var $newImages = $('#update-images'),
        $imageContainer = $('#pdpMain .product-image-container');
    if ($newImages.length === 0 || $newImages.find('img').length == 0) { return; }

    $imageContainer.html($newImages.html());
    $newImages.remove();
    initAmpDI();

    setTimeout(() => {
        initSliders();
    }, 100);
}


/**
 * @description Initialize slick sliders
 */
function initSliders () {
    const $ns = 'js-pdp-sliders';
    const $main = $(`.${$ns}__main`);
    const $tn = $(`.${$ns}__tn`);
    const isQuickView = $main.hasClass('product-quickview');
    const qvTnSlickEnabled = isQuickView && $tn.find('img').length > 1;
    const tnSlickEnabled = $tn.find('img').length > (viewportIs('desktop') ? 4 : 3);
    let desktopSettings;
    let slickArgs = {
        arrows: false,
        infinite: false,
        lazyLoad: 'progressive',
        refresh: true,
        responsive: [
        {
            breakpoint: mobileBreakpoint,
            settings: {
                dots: true,
                slidesToShow: 1
            }
        }]
    };

    switch(true) {
        case ($main.hasClass('js-product-fashion')):
            desktopSettings = {
                rows: 2,
                slidersPerRow: 2,
                slidesToShow: 2,
                draggable: false,
                swipe: false,
                dots: true
            };
            slickArgs.responsive.push({
                breakpoint: 767,
                settings: {
                    dots: true,
                    slidesToShow: 1
                }
            });
            slickArgs.responsive[0].settings.slidesToShow = 2;

            break;
        default:
            desktopSettings = {
                arrows: false,
                dots: true
            };
            break;
    }

    slickArgs.responsive.push({
        breakpoint: 5000,
        settings: desktopSettings
    });

    if ($main.find('img').length > 1) {

        $main
        .on('beforeChange', (e, slick, currentSlide, nextSlide) => {
            const $tgt = $tn.find(`.pdp-images__tn__item:eq(${nextSlide})`);
            $tn.find('.selected').removeClass('selected');
            $tgt.addClass('selected');

            if (tnSlickEnabled || qvTnSlickEnabled) {
                const slidesToShow = $tn.slick('slickGetOption', 'slidesToShow');
                const currentTnSlide = $tn.slick('slickCurrentSlide');
                const delta = nextSlide - currentTnSlide;
                if (delta < 0 || delta >= slidesToShow) {
                    $tn.slick('slickGoTo', nextSlide);
                }
            }

        })
        .slick(slickArgs);

        $tn
        .on('click', '.pdp-images__tn__link', e => {
            const $tns = $tn.find('.pdp-images__tn__link');
            const nextSlide = $tns.index(e.currentTarget);
            $main.slick('slickGoTo', nextSlide);
        });

        if (qvTnSlickEnabled) {
            $tn
            .slick({
                arrows: true,
                infinite: false,
                variableWidth: true,
                draggable: true,
                swipe: true,
                verticalSwiping: true,
                responsive: [
                {
                    breakpoint: 970,
                    settings: {
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 5000,
                    settings: {
                        slidesToShow: 4,
                        variableWidth: false,
                        vertical: false,
                    }
                }]
            });
        } else if (!isQuickView && tnSlickEnabled) {
            $tn
            .slick({
                arrows: false,
                infinite: false,
                variableWidth: true,
                draggable: true,
                swipe: true,
                verticalSwiping: true,
                responsive: [
                {
                    breakpoint: 970,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 5000,
                    settings: {
                        slidesToShow: 5,
                        variableWidth: false,
                        vertical: true,
                    }
                }]
            });
        }

    }

}


/* @module image
 * @description this module handles the primary image viewer on PDP
 **/

/**
 * @description by default, this function sets up zoom and event handler for thumbnail click
 **/
module.exports = function () {
    updatePinButton();
    imageZoom.init();
    initSliders();

    // handle product thumbnail click event
    $('#pdpMain').on('click', '.productthumbnail', function () {
        // switch indicator
        $(this).closest('.product-thumbnails').find('.thumb.selected').removeClass('selected');
        $(this).closest('.thumb').addClass('selected');

        setMainImage($(this).data('lgimg'));
    });
    // Amplience Image Viewer to display on page load
    replaceImages();
};

module.exports.setMainImage = setMainImage;
module.exports.replaceImages = replaceImages;
